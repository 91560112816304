.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  a {
    text-decoration: none;
    color: white;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.information {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, .1);

  span {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    color: #00B2F1;
  }
}

.fields {
  display: grid;
  grid-row-gap: 20px;
  margin-top: 50px;
}

.error {
  margin-top: 20px;
  color: #ED1C6F;
}

.button {
  background: rgba(9, 16, 29, .7);
  border-radius: 25px;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
}
