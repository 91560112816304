@import "../../../common/style/variables.scss";

.title {
  font-size: 22px;
  font-weight: 600;
  color: $font-color-primary;
  margin: 10px 0 10px;
}

.gdprList {
  color: $font-color-primary;
  margin-top: 5px;
  list-style-type: none;
  padding-left: 28px;

  li {
    text-indent: 0;
    white-space: pre-line;
    &:before {
      content: "-";
      text-indent:  -5px;
      padding-right: 5px;
    }
  }
}

.paragraph {
  color: $font-color-primary;

  &.confirmParagraph {
    display: flex;
    label {
      min-width: 18px;
      min-height: 18px;
      margin-right: 10px;
      margin-top: 3px;
    }
  }

  a {
    color: #00B2F1;
  }
}

.btnContainer {
  display: flex;
  justify-content: end;
}