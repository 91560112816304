html {
    --color-background: #f5f5f5;
    --color-top-bar-background: #ededed;
    --color-side-bar-background: #343a40;
    --color-separator: #d4d4d4;
    --color-disabled: #d4d4d4;
    --color-invalid: #d80700;

    --color-text: #404041;
    --color-text-light: #fafafa;
    --color-primary: #00bdf2;
    --color-complementary-pink: #ed1c6f;
    --color-complementary-purple: #711d67;
    --color-complementary-yellow: #fdb713;

    --top-bar-height: 64px;
    --color-green: #43cf21;
}
