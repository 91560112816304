.container {
  position: relative;
  width: 1170px;
  height: 526px;
  left: 50%;
  transform: translateX(-50%);

  .wrapper {
    position: absolute;
    left: 39%;

    .countries {
      margin-bottom: 2rem;
      width: 200px;
      position: relative;
      left: 27%;
    }

    .circle {
      position: relative;
      width: 435px;
      height: 429px;
      margin: 0 auto;
    }
  }
}
