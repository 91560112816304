.accountsContainer {
 display: flex;
 flex-direction: row;
 align-items: center;
 margin-bottom: 1rem;
}

.accountsContainer label {
 font-weight: bold;
 margin-right: 1rem;
}

.accountsMultiSelect {
 width: 17rem;
}