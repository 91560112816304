.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveButton {
  padding-right: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formRow {
  margin-bottom: 1rem;
  width: 80%;
  align-self: center;
}

.topForm label{
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.form label {
  font-weight: bold;
}

.form select {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.saveButtonContainer {
  margin-right: 2rem;
}

.ffiHeading, .ffiContent {
  justify-content: flex-start;
}

.formTop hr {
  border-top: 1px dashed;
}

.title {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: #1F303D;
  margin-top: 0;
}


.subtitle {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 40px;
}

.container {
  display: flex;
}

.modalContainer {
  width: 100px;
  inset: 0;
}

.formContainer {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
}

.input {
  border: 0;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: var(--cp-color-primary) !important;
  margin-top: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  font-family: 'Open Sans' !important;
}

.logo {
  align-self: center;
  width: 150px;
}

.buttonContainer {
  text-align: -webkit-center;
  width: 90%;
}

.errorMessage {
  width: 70%;
}