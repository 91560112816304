.textarea {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(27, 45, 65, 0.88) 41.01%, rgba(27, 45, 65, 0.15) 100%);
  resize: none;
  color: white;
  padding: 6px 19px 6px 10px;
  width: 100%;
  height: 95px;
  font-size: 14px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
  }
}
