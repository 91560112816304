.mapGlMarker {
  z-index: 5;
  .root {
    position: relative;

    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

    .pin {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transform: translateX(-50%) translateY(-100%);
      cursor: pointer;

      &.big {
        width: 35px;
        height: 40px;
        svg {
          width: 35px;
          height: 40px;
        }
      }

      &.small {
        width: 20px;
        height: 24px;
        svg {
          width: 20px;
          height: 24px;
        }
      }
    }
    .name {
      position: absolute;
      top: 8px;
      display: inline-flex;
      width: 140px;
      height: 21px;
      background-color: white;
      padding: 0 8px;
      border-radius: 3px;
      align-items: center;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
      }
    }
  }
}
