.modal {
  background-color: rgba(255, 255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 10px;
  border: 2px solid #ae2634;
  border-radius: 15px;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  padding: 8px;
  flex: 1;
}
