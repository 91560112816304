.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: var(--color-text-light);
}
