.title {
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.saveButton {
  padding-right: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 40rem;
  padding: 0 100px;
}

.formRow {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.topForm label{
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.form label {
  font-weight: bold;
}

.form input {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.form input[type=checkbox] {
  width: unset;
}

.form select {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.saveButtonContainer {
  margin-right: 2rem;
}

.ffiHeading, .ffiContent {
  justify-content: flex-start;
}

.formTop hr {
  border-top: 1px dashed;
}
