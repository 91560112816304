@import "../../style/mixin";

.tooltip {
  @include blurred-background(25px);
  color: white;
  border-radius: 5px;
  padding: 5px 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 10px 0 0 0;
      font-size: 18px;
    }
  }

  .actions {
    display: flex;
    justify-content: end;
    grid-column-gap: 10px;
    margin-bottom: 5px;
  }
}
