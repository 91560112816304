.fields {
  display: grid;
  grid-row-gap: 15px;
  margin: 25px 0;
}

.actions {
  display: grid;
  grid-row-gap: 10px;

  .button {
    background: rgba(9, 16, 29, .7);
    border-radius: 25px;
    padding: 8px 20px;
    color: white;
    cursor: pointer;
  }

  .textButton {
    color: white;
    text-decoration: none;
  }
}

.error {
  margin-bottom: 20px;
  color: #ED1C6F;
}
