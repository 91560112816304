.modal {
    min-width: 60vw;
    min-height: 80vh;
    background-color: var(--color-background);
    display: flex;
    position: absolute;
    top: 5vh;
    left: 20vw;
    outline: none;
    border: 1px solid var(--color-separator);
    border-radius: 4px;
    max-height: 80vh;
    /*overflow: scroll;*/
    padding: 10px;
    border: 2px solid #c6e9ec;
    border-radius: 15px;
}

.modalConfirm {
    min-width: 60vw;
    min-height: 80vh;
    background-color: var(--color-background);
    display: flex;
    position: absolute;
    top: 5vh;
    left: 20vw;
    outline: none;
    border: 1px solid var(--color-separator);
    border-radius: 4px;
    max-height: 80vh;
    /*overflow: scroll;*/
    padding: 10px;
    border: 2px solid #ff0000;
    border-radius: 15px;
}

.sideBySide{
    display:flex; flex-direction:row;
}

.modalContent {
    padding: 8px;
    flex: 1;
    overflow-y: auto;
}

.topBar {
    display: flex;
    flex-direction: column;
    display: flex;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;
    overflow-y: auto;
}

.createMoreItemsMessage {
    justify-content: center;
    align-items: center;
}

.emphasized {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    font-size: 25px;
}

.modalTopButtons {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: center;
}

.optionIcon {
    fill: #4a9cb3 !important;
}

.itemSelector {
    width: 30vmax;
    margin-bottom: 16px;
    padding-top: 10px;
    padding-bottom: 1px;
}

.delimiter {
    height: 2px;
    width: 100%;
}

.formRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30vmax;
    margin-bottom: 16px;
}

.formRowPostProcessing{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 45vmax;
    margin-bottom: 16px;
}

.fieldName {
    display: flex;
    font-weight: bold;
    text-align: left;
    width: 40%;
    flex-direction: row;
    align-items: center;
}

.fieldNamePostProcessing {
    display: flex;
    font-weight: bold;
    text-align: left;
    width: 90%;
    flex-direction: row;
    align-items: center;
}

.valueContainer {
    display: flex;
    flex-direction: column;
    /*width: var(--value-row-width);*/
    width: 40%;
    border: 1px solid #838383;
    max-width: 40%;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    /*width: var(--value-row-width);*/
    min-width: 5%;
    width: 5%;
    max-width: 5%;
    horiz-align: left;
}
.sideBySideInputContainer {
    display: flex;
    flex-direction: column;
    /*width: var(--value-row-width);*/
    min-width: 15%;
    width: 35%;
    max-width: 35%;
    horiz-align: left;
    max-height: 10%;
}

.addRiContainer {
    display: flex;
    flex-direction: row;
    width: var(--value-row-width);
}

.fieldErrorMessage {
    display: flex;
    flex-direction: column;
    color: var(--color-invalid);
    margin-top: 4px;
}

.itemTypeName {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.reportItem {
    display: flex;
    flex-direction: column;
    opacity: 1;
    animation: fade 0.5s linear;
    width: 100%;
}

.reportItemTitleContainer {
    display: flex;
    flex-direction: row;
    padding-top: 13px;
    padding-bottom: 5px;
}

.reportItemTitle {
    display: flex;
    flex-direction: row;
    background-color: #d4d4d4;
    opacity: 1;
    animation: fade 0.5s linear;
    width: 100%;
}

.reportItemSettings {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.addRiDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: 86.5%;
    /*width: content-box;*/
}

.addRiButtonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: fit-content;
}

.removeRiButtonContainer {
    display: flex;
    flex-direction: row;
    min-width: fit-content;
    padding-left: 9px;
    align-items: center;
}

.reportItemType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.modalBottomButtons {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: #838383;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    color: var(--color-text-light);
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
    pointer-events: auto !important;
}

.aNoStyle{
    text-decoration: none;
    color: black;
}