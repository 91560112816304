.legend {
  margin-top: 38px;
  display: flex;
  flex-direction: column;

  .legendRow {
    display: flex;
    justify-content: center;
    grid-column-gap: 25px;
    margin-bottom: 5px;

    .option {
      display: flex;
      grid-column-gap: 9px;
      align-items: center;

      .content {
        color: white;
      }

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.lightBlue {
          background: #7CC6DE;
        }

        &.blue {
          background: #00B2F1;
        }

        &.yellow {
          background: #F2C94C;
        }

        &.orange {
          background: #F2994A;
        }

        &.red {
          background: #EB5757;
        }
      }
    }
  }
}
