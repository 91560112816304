.timeFrames {
  border:none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 6.5px 6.5px 6.5px 15px;
  width: 100%;

  .iconContainer {
    border-radius: 25px;
    backdrop-filter: blur(15px);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(9, 16, 29, 0.3);

    &.active {
      background-color: #00b2f1;
    }
  }
}