@import '../../../../common/style/mixin.scss';

.menu {
  position: fixed;
  top: 755px;
  left: 389px;
  z-index: 99;
  @include blurred-background(80px);
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(19, 29, 37, 0.3);

  &.full {
    height: 425px;
  }

  &.part {
    height: 240px;
  }
}

.header {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 0;
  }

  .icon {
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.rows {
  .item {
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding: 20px 0;
    display: grid;
    grid-row-gap: 10px;

    .row {
      display: flex;
      align-items: center;
      grid-column-gap: 50px;
      padding: 0 20px 0 20px;
    }
  }
}

.label {
  white-space: nowrap;
  font-size: 14px;
  color: rgba(255, 255, 255, .6);
  width: 100px;
}

.buttons {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  justify-content: flex-end;
}
