.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reportContainer {
    display: flex;
    flex-direction: column;
    width: 24%;
    min-width: 17rem;
    height: 396px;
    color: inherit;
    text-decoration: inherit;
    margin: 8px;
}

.report {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    height: 396px;
    overflow: hidden;
    cursor: pointer;
}

.heading {
    display: flex;
    background-color: #c6c6c6;
    height: 50%;
    justify-content: flex-end;
}

.headingActions {
    margin: 0.5rem 0.4rem;
}

.headingActions * {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.reportProperties {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
}

.reportProperty {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.reportPropertyName {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
}

.reportPropertyValue {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.statusReady {
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.statusProcessing {
    display: flex;
    flex-direction: column;
    color: var(--color-green);
}

.statusError {
    display: flex;
    flex-direction: column;
    color: #ae2634;
    background-color: #ffd699;
}

.optionDeleteIcon {
    fill: #bb3e3e !important;
    cursor: pointer;
}

.duplicateReportIcon {
    fill: #4a9cb3 !important;
}

.isVisible {
    width: fit-content;
}

.options {
    display: flex;
    grid-column-gap: 20px;
}

.collaborationRow {
    display: grid;
    grid-template-columns: max-content 225px;
    align-items: center;
    grid-column-gap: 15px;
}
