.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;

  &:last-child {
    margin-left: 1rem;
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
  }

  .yearContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    padding: 0;
    width: 100%;

    &:hover {
      outline: 2px solid #00b2f1;
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      margin: 6.5px 10px 6.5px 15px;
    }

    .buttonContainer {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-right: 4px;

      button {
        border: none;
        outline: none;
        cursor: pointer;
        height: 26px;
        width: 28px;
        background: rgba(9, 16, 29, 0.3);
        border-radius: 25px;

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

    }
  }
}
