.linkWithIcon {
  text-decoration: none;
  display: flex;
  margin: 15px;
  align-items: center;
  padding: 0;

  &:hover .title {
    display: flex;
    color: #00BDF2;
  }

  .icon {
    display: flex;
    margin-right: 16px;
  }
  .title {
    color: white;
    font-size: 14px;
  }

  &.selected .title {
    color: #00BDF2;
  }
}
