.title {
  color: rgba(255, 255, 255, 0.6);
  margin-right: 20px;
  width: 45px;
  display: inline-block;
}

.option {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  display: inline-block;
  width: 24px;
}

.rows {
  display: grid;
  grid-row-gap: 9px;
}
