.root {
  min-width: 270px;

  color: var(--cp-color-secondary)
}

.title {
  margin:  0 0 .6em;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.6;
}

.features {
  margin: 20px 0;
  padding: 0;
}

.features th {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  padding-right: 1rem;
}

.features td {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
}