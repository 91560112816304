.card {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.25);
  backdrop-filter: blur(40px);
  padding: 10px 10px 5px;
  width: 100%;
  cursor: pointer;
  height: 164px;

  &.active {
    img {
      border: 3px solid #00B2F1;
    }
  }

  .text {
    font-weight: 600;
    color: white;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    max-width: 263px;
  }

  img {
    width: 100%;
    border-radius: 3px;
    height: 120px;
  }
}
