.label {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  transition: .2s;
  display: flex;

  &.checked {
    background: #00B2F1;
    border-color: #00B2F1;
    .icon {
      svg {
        opacity: 1;
      }
    }
  }

  .input {
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 15px;
      height: 10px;
      opacity: 0;
      transition: .2s linear;
    }
  }
}
