.formContainer {
    display: flex;
    flex-direction: column;
    width: 800px;
    align-items: center;

    --value-row-width: 60%;
}

.delimiter {
    height: 2px;
    width: 100%;
}

.formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.fieldName, .visualizationURL {
    display: flex;
    font-weight: bold;
    text-align: left;
    width: 40%;
    flex-direction: row;
    align-items: center;
}

.datePickerContainer {
    display: flex;
    flex-direction: row;
    width: 480px;
}

.input {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    width: 100%;
}

.sideBySide {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
}

.inputInvalid {
    display: flex;
    flex-direction: column;
    border-color: var(--color-invalid);
}

.textarea {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    resize: none;
    height: 112px;
    width: 100%;
}

.select {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    background-color: white;
    width: 100%;
}

.itemTypeCheckbox {
    display: flex;
    flex-direction: column;
    outline: none;
    width: 24px;
    height: 24px;
}

.reportCategoryMultiSelect {
    width: 100%;
    display: block;
}

.poiMultiSelect {
    width: 100%;
    display: block;
}

.itemType {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: var(--value-row-width);
}

.valueContainer {
    display: flex;
    flex-direction: column;
    width: var(--value-row-width);
}

.addRiContainer {
    display: flex;
    flex-direction: row;
    width: var(--value-row-width);
}

.fieldErrorMessage {
    display: flex;
    flex-direction: column;
    color: var(--color-invalid);
    margin-top: 4px;
}

.itemTypeName {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.reportItem {
    display: flex;
    flex-direction: column;
    opacity: 1;
    animation: fade 0.5s linear;
    width: 100%;
}

.reportItemTitleContainer {
    display: flex;
    flex-direction: row;
    padding-top: 13px;
    padding-bottom: 5px;
}

.reportItemTitle {
    display: flex;
    flex-direction: row;
    background-color: #d4d4d4;
    opacity: 1;
    animation: fade 0.5s linear;
    width: 100%;
}

.reportItemSettings {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.addReportItemButtonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.createButtonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.addRiDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: 86.5%;
    /*width: content-box;*/
}

.addRiButtonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: fit-content;
}

.removeRiButtonContainer {
    /*color: #ed1c6f !important;*/
    display: flex;
    flex-direction: row;
    min-width: fit-content;
    padding-left: 9px;
    align-items: center;
}

.reportItemType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.datesPickerButtonsContainer {
    /*color: #ed1c6f !important;*/
    display: flex;
    flex-direction: column;
    padding-left: 13px;
    padding-right: 3px;
    /*min-width: fit-content;*/
    /*padding-left: 9px;*/
    /*align-items: center;*/
    justify-content: center;
}

.duplicateReportInfoMessage {
    color: var(--color-invalid);
}
