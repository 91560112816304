@import '../../../common/style/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  .value {
    font-weight: normal;
    font-size: 12px;
    color: $font-color-secondary;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &.isActive {
      opacity: 1;
      color: $visuals-background-color-active;
      font-weight: 600;
      font-size: 13px;
    }
  }

}
