.formContainer {
    display: flex;
    flex-direction: column;
    width: 800px;
    align-items: center;
    --value-row-width: 60%;
}

.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.label {
    font-weight: bold;
}

.name {
    padding: 0.25rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid var(--color-separator);
    width: var(--value-row-width);
}

.description {
    padding: 0.25rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid var(--color-separator);
    resize: none;
    width: var(--value-row-width);
}

.countries {
    padding: 0.25rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid var(--color-separator);
    width: var(--value-row-width);
}

.submitButton {
    margin-top: 1.5rem;
}
