@import '../../style/mixin.scss';
@import '../../style/variables.scss';

.languageSelector {
  .selectedLanguage {
    position: fixed;
    left: 15px;
    bottom: 15px;

    button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 0;
      cursor: pointer;
    }
  }

  .languagesContainer {
    position: fixed;
    z-index: 1000;
    left: 42px;
    bottom: 42px;
    @include blurred-background(80px);
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
    
    display: flex;
    flex-direction: column;
    width: 180px;
    padding: 10px;
  
    button {
      width: 100%;
      padding: 5px;
      margin: 5px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        padding-left: 10px;
        color: $font-color-secondary;
      }
    }
  }

}
