.container {
  margin-bottom: 1rem;
  width: 100%;

  .item {
    display: flex;
    justify-content: space-between;
  }

  .name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: left;
    text-decoration: unset !important;

    &:nth-child(1) {
      width: 65%
    }

    &:nth-child(2) {
      width: 30%
    }
  }

  .value {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: right;
    white-space: nowrap;
    vertical-align: top;
    text-decoration: unset !important;
  }

  .skeletonName {
    width: 190px;
  }
  .skeletonValue {
    width: 25px;
  }
}
