@import '../style/mixin.scss';

.wrapper {
  @include blurred-background(70px, rgba(255, 255, 255, 0.1), rgba(79, 92, 109, 0.95));
  position: fixed;
  box-shadow: 0px 11px 20px rgba(0, 0, 0, .3);
  border-radius: 5px;
  z-index: 1000;
  padding: 15px 0 10px 0;
  left: 71px;
  overflow: hidden;

  .content {
    display: grid;
    grid-template-columns: repeat(4, 94px);
    grid-gap: 5px;
    margin: 5px 0 15px;
    height: 281px;
    overflow-y: scroll;
    padding: 0px 5px 0 10px;
  }

  .title {
    font-size: 16px;
    margin: 0;
    padding: 0 10px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    gap: 20px;
    justify-content: flex-end;
  }

  .location {
    @include blurred-background(40px, rgba(255, 255, 255, 0.1), rgba(79, 92, 109, 0.95));
    box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.25);
    padding: 7px 7px 5px;
    cursor: pointer;
    height: 90px;
    img {
      width: 80px;
      height: 50px;
      border-radius: 2px;
    }

    p {
      width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      margin-top: 5px;
    }

    &.active {
      img {
        border: 2px solid #00B2F1;
      }
    }
  }
}
