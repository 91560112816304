@import "src/customerPortalv2/common/style/mixin";
@import "src/customerPortalv2/common/style/variables";

.menu {
  @include blurred-background(15px);
  color: $font-color-primary;
  border-radius: 5px;
  padding: 5px 0;

  .item {
    padding: 10px 15px;
    cursor: pointer;
    &:hover {
      color: $visuals-background-color-active;
    }
  }
}
