@import '../../../../../common/style/mixin.scss';

.tooltip {
  @include blurred-background(60px, transparent, rgba(87, 101, 119, 0.95));
  color: white;
}

.chartWrapper {
  width: 100%;
}
