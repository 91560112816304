.wrapper {
  margin-bottom: 20px;
  .content {
    font-size: 14px;
    margin-top: 20px;

    p {
      margin: 0;
    }
  }

  .footer {
    font-size: 12px;
    width: 370px;
    margin-top: 15px;
  }

  .highlight {
    color: #00B2F1;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
