@import "../../../style/variables.scss";

.recurringReportTooltip {
  position: relative;
  display: flex;
  justify-content: right;

  svg {
    width: 20px;
    height: 20px;
  }

  .tooltipContent {
    position: absolute;
    top: 28px;
    right: 0;
    width: 400px;
    background: $visuals-background-color;
    border: 1px solid $visuals-line-color;
    border-radius: 5px;
    color: $font-color-primary;
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: 14px;
    .title {
      font-weight: 600;
      padding-bottom: 5px;
    }
  }
}
