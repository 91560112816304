.field {
  display: grid;
  grid-column-gap: 11px;
  align-items: center;

  .error {
    color: #ED1C6F;
    font-size: 12px;
    margin: 0;
    text-align: left;
    position: absolute;
  }

  .label {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);

    .asterisk {
      color: #FA629E;
    }
  }
}
