.wrapper {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;

  input,label {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  label {
    line-height: 2rem;
    display: inline-block;
    z-index: 2;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, .2);
    transition: .5s linear;
    cursor: pointer;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #1F303D;
      border-radius: 50%;
      opacity: 0;
      transition: .5s linear;
    }
  }

  input {
    z-index: 1;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  &.checked {

   label {
    background: #00B2F1;
     border-color: #00B2F1;
     &:after {
       opacity: 1;
     }
   }
  }
}
