@import "../../../style/variables.css";

.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.sideBar {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--color-side-bar-background);
    min-width: 196px;
    max-width: 296px;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 5;
    overflow: auto;
}

.topBar {
    display: flex;
    background-color: var(--color-top-bar-background);
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: var(--top-bar-height);
    padding: 16px;
}

.page {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 32px;
}

.pageTitle {
    padding-right: 20px;
    margin: 0;
}

.pageTitleContainer {
    display: flex;
    flex-direction: row;
    margin: 0 0 32px;
    align-items: center;
}
