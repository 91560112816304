.information {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  justify-content: center;
  font-size: 14px;
  background: rgba(0,178,241,.15);
  padding: 5px;
  border-radius: 20px;
  margin-top: 25px;
}

.additionalInformation {
  font-size: 14px;
  margin-top: 30px;

  a {
    color: white;
    text-decoration: none;
    color: #00b2f1;
  }
}


.goBack {
  color: white;
  display: inline-block;
  text-decoration: none;
  margin-top: 25px;
}
