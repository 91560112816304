@import "../../../../common/style/variables.scss";

.locationsCountDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .label {
    color: $font-color-secondary;
    font-size: 14pxs;
  }
  .dropdownWrapper {
    width: 180px;
  }
}
