.root {
  display: flex;
  align-items: center;
}

.title {
  margin: 0 0 0.2em;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, .6)
}

.scale {
  display: flex;
  margin: 5px 0;
  gap: 1px;
}

.scale__color {
  display: block;
  width: 7.3px;
  height: 8px;
  background-color: var(--color-fill);
}
.scale__color::after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.range {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.range__value {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--cp-white);
}

.information {
  display: flex;
  align-items: center;
  grid-column-gap: 7px;
  margin-left: 15px;
}
