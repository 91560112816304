@import "../../../style/variables.css";

.container {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
    padding-top: 24px;
    border-bottom: 1px solid var(--color-separator);
    text-align: center;


    --element-height: 32px;
}

.fieldName {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.settingType {
    display: flex;
    flex-direction: column;
    height: var(--element-height);
    justify-content: center;
    align-items: center;
}

.input {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    width: 100%;
    height: var(--element-height);
}

.select {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    width: 100%;
    height: var(--element-height);
}

.column {
    display: flex;
    flex-direction: column;
    flex: 2;
}

.smallColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.column + .column {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
    align-items: center;
}

.possibleValuesForSimpleTextNote {
    display: flex;
    flex-direction: column;
    height: var(--element-height);
}

.addPossibleValueButton {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    height: var(--element-height);
}

.removePossibleValueButton {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    margin-left: 8px;
    height: var(--element-height);
}

.possibleValue {
    display: flex;
    margin-bottom: 8px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.removeSettingButton {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    margin-left: 8px;
    height: var(--element-height);
}
