.wrapper {
  position: absolute;
  right: 0;
  z-index: 999;
  bottom: 0;
  left: 0;
  margin: 0 15px 19px 10px;

  .options {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: end;
  }

  .header {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding: 5px 0;

    &.collapsed {
      grid-template-columns: max-content max-content;
    }

    .location {
      overflow: hidden;
      width: 110px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 15px;
    }

    .share {
      margin-right: 5px;
    }

    .sliderControl {
      display: flex;
      align-items: center;
      margin: 0 20px;
      width: 100%;
    }

      .dots {
        display: flex;
        justify-content: space-between;
        grid-column-gap: 5px;
        margin: 0 auto;
      }
  }

  .information {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 10px rgba(19, 29, 37, 0.4);
    backdrop-filter: blur(35px);
    border-radius: 5px 0px 5px 5px;
    padding: 0 5px;
  }

  .items {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    padding-bottom: 5px;
    transition: .2s linear;
    overflow: hidden;
    height: 168px;

    &.hidden {
      height: 0px!important;
      padding-bottom: 0;
    }

    &.collapsed {
      overflow-y: scroll;
    }
  }
}
