.container {
  display:flex;
  background: var(--cp-active-fill-color);
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.4;
  color: white;
  flex-direction: column;

  b {
    font-weight: 600;
  }

  .messageContainer {
    display: inline-flex;
    flex-direction: column;
  }

  .footer {
    color: var(--cp-color-primary);
    font-size: 12px;

    a:visited {
      color: var(--cp-color-primary);
    }
  }
}
