@import "../../style/mixin.scss";

.wrapper {
  display: grid;
  grid-row-gap: 5px;
  .button {
    @include blurred-background(15px);
    box-shadow: 0 0 10px rgba(19, 29, 37, 0.4);
    //width: max-content;
    padding: 7px 11px;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.custom {
      padding: 5px 11px;
    }
  }
}
