@import '../../style/mixin';

.popup {
  @include blurred-background(20px);
  z-index: 8;
}

.popup :global(.mapboxgl-popup-tip) {
  display: none;
}

.popup :global(.mapboxgl-popup-content) {
  padding: 10px;

  background: rgba(7, 13, 26, 0.3);
  box-shadow: 0 0 10px rgba(19, 29, 37, 0.4);
  border-radius: 5px;
}
