.tooltip {
  position: absolute;
  z-index: 10;

  &.unSave {
    right: 120px;
    bottom: 100px;
  }

  &.unSaveRight {
    left: 425px;
    bottom: 150px;
  }
}