.container {
  position: relative;

  .icon {
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-52%);
    cursor: pointer;
    height: 15px;
    svg {
      width: 20px;
      height: 15px;
      path {
        fill: #f5f5f5;
      }
    }
  }
}
