@import '../../../common/style/mixin.scss';

.container {
  position: absolute;
  top: 27px;
  left: 0;
  z-index: 100;
  width: 100%;
  color: #FFFFFF;
  font-size: 14px;
  visibility: hidden;

  .menu {
    overflow: hidden;
    max-height: 248px;
    visibility: visible;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      margin: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
    }

    @include dropdownAnimation();

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 6px;
      background: unset;
      opacity: 0;

      @include blurred-background(20px, rgba(255, 255, 255, 0.1), rgba(79, 92, 109, 0.95));

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.disabled {
        opacity: 0.5;
      }

      .image {
        width: 15px;
        height: 11px;
        margin-right: 5px;
      }
    }

    .item:hover {
      background: #00B2F1!important;
      color: #1A1B1E;
      font-weight: 600;
      &.disabled {
        color: unset;
        background: unset;
      }
    }
  }
}
