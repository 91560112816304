.items {
  position: absolute;
  left: 42%;
  top: -9%;
  transform: translateX(-50%);
  width: 435px;
  height: 429px;
  z-index: 10;
  .item {
    position: absolute;

  }
}
