.input {
  color: white;
  padding-right: 20px;
  width: 100%;
  font-size: 14px;
  padding-bottom: 4px;

  &.border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:focus,  &:hover {
      border-color: #00B2F1;
    }
  }

  &::placeholder {
    color: white;
  }

  &.error {
    border-color: #ED1C6F;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
}
