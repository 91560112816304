.wrapper {
  position: relative;
  width: 1199px;
  margin: 0 auto;

  .display {
    //overflow-y: hidden;

    .switcher {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
    }
    .singleColumnSwitcher {
      display: flex;
      flex-direction: row;
    }
  }
}
