.container {
  color: white;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    :first-child {
      margin-right: 15px;
    }
  }
}