@import "../../style/variables.scss";

.page {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #1f3045;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center top;
  background-attachment: fixed;
  background-size: cover;

  &.withDemoBar {
    height: calc(100vh - 38px);
  }

  .basic {
    position: absolute;
    right: 0;
    display: block;
    height: 100%;
    overflow: hidden;
    transition: .4s;

    &.full {
    }

    &.partial {
      position: absolute;
      right: 0;
    }
  }


  .sidebar {
    position: absolute;
    z-index: 2000;
  }
}

.demoBar {
  width: 100%;
  height: 38px;
  padding-top: 8px;
  background-color: $visuals-background-color-active;
  text-align: center;
  color: $font-color-primary;
  font-size: 15px;
  font-weight: 600;
  a {
    color: $font-color-primary;
  }
}
