.locationTable {
    width: 100%;
}

.locationTable, .locationTable td, .locationTable th {
    border: 1px solid var(--color-separator);
    border-collapse: collapse;
    padding: 4px;
    text-align: center;
}

.actionButton {
    color: var(--color-primary);
    cursor: pointer;
    border: none;
    background: none;
}

.checkBox {
    width: 16px;
    height: 16px;
}

.groupActions {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.modal {
    min-width: 400px;
    min-height: 150px;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 150px);
    outline: none;
    border: 1px solid var(--color-separator);
    border-radius: 4px;
}

.modalButtons {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: center;
}

.modalButtons * {
    margin-left: 16px;
    margin-right: 16px;
}

.modalContent {
    padding: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.deleteMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deleteMessageContent {
    max-height: 300px;
    overflow-y: auto;
    list-style-type: none;
    text-align: center;
    padding-left: 0;
}

.emphasized {
    font-weight: bold;
}

.locationToBeDeleted {
    margin-top: 4px;
    margin-bottom: 4px;
}
