.inputContainer {
    border-bottom: 1px solid #D4D4D4;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    &:focus-within {
        border-bottom: 1px solid #00B2F1;
    }
    &:hover {
        border-bottom: 1px solid #00B2F1;
    }

    .inputIcon path {
        fill: #bbb !important;
    }

    .input {
        border: none;
        font-size: 14px;
        padding-left: 7px;
        width: 100%;
        margin: 0 5px;
        background: transparent;
        background-color: white !important;
        &:focus {
            outline: none;
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
          }
    }
}

.errorInputContainer {
    @extend .inputContainer;
    border-bottom: 1px solid red;
    &:focus-within {
        border-bottom: 1px solid red;
    }
    &:hover {
        border-bottom: 1px solid red;
    }
}
