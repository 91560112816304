@import '../../common/style/variables.scss';

.stepThreeContainer {
    color: $font-color-primary;
    .tableRow {
        grid-template-columns: 30px auto;

        .reportItem {
            display: flex;
            flex-direction: column;
            .reportItemTitle {
                color: $font-color-primary;
            }
            .reportItemSubtitle {
                color: $font-color-secondary;
                .readMore {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        div:first-child {
            opacity: 1;
        }
    }

    .nameContainer {
        width: 22px;
        align-items: start;
    }

    .additionalDataPoints {
        color: $font-color-primary;
        margin: 35px 0 20px;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;
            grid-column-gap: 15px;
        }
        .error {
            color: $font-color-error;
        }
    }
}
