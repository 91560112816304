.title {
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deleteButton {
  padding-right: 8px;
}
