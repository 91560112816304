.toggle {
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem 2rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  background: transparent;
  overflow: auto;
  height: 100%;

  .headingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin: 0;
    }
    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      opacity: 0.6;
      margin: 0;
      .tooltipIcon {
        margin-left: 10px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .icon {
      cursor: pointer;
    }
  }
}
