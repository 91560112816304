@import '../../style/mixin.scss';

.contentContainer {
  position: absolute;
  top: 0;
  z-index: 8;
  padding: 5px 30px 15px 30px;
  right: 0;
  background: linear-gradient(90deg, rgba(27, 45, 65, 0.8) 41.01%, rgba(27, 45, 65, 0.5) 100%);
  transition: .4s;
  height: 100vh;

  &:before {
    @include blurred-background(15px, transparent, rgba(29,47,67, 0.95));
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    backdrop-filter: blur(15px);
  }

  &.full {
    width: 100%;
  }

  &.partial {
    width: calc(100% - 360px);
  }
}
