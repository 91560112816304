.card {
  cursor: pointer;
  height: 74px;

  &.fullSize {
    width: 100%;
  }

  &.partSize {
    width: 80px;
  }

  &.active {
    img {
      border: 3px solid #00B2F1;
    }
  }

  .text {
    color: white;
    font-size: 12px;
    opacity: 0.6;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    max-width: 263px;
  }

  .image {
    width: 100%;
    border-radius: 3px;
    object-fit: cover;
    object-position: 50% 50%;
    height: 50px;
  }

  .skeletonText {
    height: 6px;
  }
}
