.wrapper {
  margin-top: 66px;
  .buttons {
    display: flex;
    grid-column-gap: 15px;
    margin-top: 35px;
    position: relative;
    z-index: 110;
  }
}



