.errorMessage {
    color: red;
    margin: 8px;
    font-size: 14px;
}

.captcha {
    margin: 20px 0 10px;
}

.capsule {
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 40px;
    background-color: rgba(0, 178, 241, 0.15);
    svg {
        margin-right: 10px;
    }
}
