@import 'src/customerPortalv2/common/style/mixin';

.form {
  position: relative;
  z-index: 8;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 5px 5px 0;
  padding: 20px 20px 13px;
  overflow-x: hidden;
  overflow-y: auto;

  &:before {
    @include blurred-background(15px, transparent, rgba(29,47,67, 0.95));
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .fields {
    position: relative;
    margin-top: 28px;
    .field {
      padding: 13px 0;

      &:not(:last-child) {
        //border-bottom: 1px solid rgba(255, 255, 255, .1);
      }

      .label {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        margin-right: 10px;
      }

      .value {
        display: inline-block;
        color: white;
        margin: 0 10px 0 24px;
      }

      .icon {
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }

      .dropdown {
        padding-top: 0;
      }
    }

    .tooltip {
      position: absolute;
      z-index: 10;

      &.aoiInformation {
        right: 195px;
        bottom: 50px;
      }

      &.unSave {
        position: fixed;
        right: 110px;
        bottom: 50px;
      }
    }
  }

  .title {
    font-size: 16px;
    color: white;
    margin: 0;
  }

  .subTitle {
    color: rgba(255, 255, 255, .6);
    font-size: 14px;
    margin: 0;
  }

  .actions {
    display: flex;
    grid-column-gap: 15px;
    justify-content: center;
    margin-top: 40px;
  }

  .lineField {
    display: grid;
    grid-template-columns: 140px 1fr;

    .labelTooltipContainer {
      display: flex;
      flex-direction: row;
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      text-align: left;

      span {
        color: #FA629E;
      }
    }

    .tooltipIconContainer {
      position: relative;
      z-index: 2;
    }

    .skeletonIcon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .tooltipIcon {
      display: block;
      margin: 3px 6px 0 7px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: white;
        }
      }
    }
  }
}
