.component {
  position: relative;
  width: 75px;
  height: 75px;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 32px;
  }
}
