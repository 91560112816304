.root {
  padding: 20px;
  height: 100%;

  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0 0 2em;

  font-size: 20px;
  color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.editPolygonBtnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.actionBtnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
