@import "../../../style/variables.css";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*min-width: 296px;*/
    /*height: 300px;*/
    /*border: 1px solid #711d67;*/
    /*border-spacing: 50px;*/
    /*padding: 10px;*/
    /*postition: absolute;*/
    /*bottom: 0;*/
    flex: 1;
    /*height:100vh;*/
    /*height:100vw;*/
    /*min-height: 100vh;*/
    /*height: 100vh;*/
    /*max-height: 100vh;*/
    /*border: #711d67 double 8px;*/
    box-sizing: border-box;
}

.footer {
    width: 100%;
}

/*.mainMenu {*/
/*    background-color: #ffffff;*/
/*    width: 400px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    height: auto;*/
/*    padding: 30px;*/
/*    box-shadow: 0 -15px 100px 20px rgba(0,0,0,.25);*/
/*    border-radius: 4px;*/
/*}*/


.link {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: var(--color-text-light);
    text-decoration: none;
    margin-top: 16px;
}

/*.headerNavLink {*/
/*    display: flex;*/
/*    color: #a1a1a1;*/
/*    padding: 12px 19px;*/
/*    text-decoration: none;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/

.headerUser {
    display: flex;
    width: 100%;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-transform: capitalize;
}

.headerNavList {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    padding: 15px 0;
}

.headerNavListMain {
    /*flex-grow: 0.8vh;*/
    /*height: 79vh;*/
    width: 100%;
}

.headerNavItem {
    display: block;
    margin: 0;
    /*padding: 1px;*/
}

/*.menusDelimiter {*/
/*    min-height: 200%;*/
/*}*/

.headerNavLink {
    display: flex;
    color: #a1a1a1;
    padding: 12px 19px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
}

.headerNavLinkActive {
    background-color: rgba(0, 0, 0, 0.25);
    color: #fafafa;
}

.textItem {
    padding-left: 8px;
}

.menuItemsDelimiter {
    height: 2px;
    width: 100%;
    /*padding-top: 15px;*/
}

.headerNavLinkChangePassword {
    display: flex;
    color: #a1a1a1;
    text-decoration: none;
    cursor: pointer;
    padding: 0.75rem 1.1875rem 15px;
    align-self: flex-start;
}

.headerNavLinkLogout {
    display: flex;
    color: #a1a1a1;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    /*margin-bottom: auto;*/
    /*margin-top: auto;*/
    align-self: flex-end;
    padding-bottom: 15px;
}
