@import '../style/mixin.scss';

.wrapper {
  position: relative;
  color: white;

  &.active {
    .activeItem {
      @include blurred-background(40px, rgba(255, 255, 255, 0.1), rgba(79, 92, 109, 0.95));
      font-weight: 600;
      padding: 6px 10px;
      border-radius: 5px 5px 0 0;
    }

    .list {
      width: 100%;
    }
  }
}

.select {
  position: relative;
  z-index: 2;
  font-size: 14px;
}

.list {
  position: absolute;
  padding-bottom: 5px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
  visibility: visible;

  .item {
    @include blurred-background(40px, rgba(255, 255, 255, 0.1), rgba(79, 92, 109, 0.95));
  }

  @include dropdownAnimation()
}

.activeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  cursor: pointer;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
  }
}

.item {
  padding: 6px 10px;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  &:hover {
    cursor: default;
    &:not(.disabled) {
      cursor: pointer;
      font-weight: 600;
      background: var(--cp-active-fill-color);
      color: var(--cp-default-icon-color);
    }
  }

  &.disabled {
    opacity: .5;
  }
}
