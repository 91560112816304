.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.container {
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    flex-direction: row;
}

.selected{
    height: 40px;
    margin: 0 0 0 20px ;
    background-color: #A5A5A5;
    border: solid 1px
}

.notSelected{
    height: 40px;
    margin: 0 0 0 20px ;
    background-color: #DBDBDB;
    border: none;
}
