.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 15px;

}

.title {
  margin: 0 0 10px 0;
}

.row {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-column-gap: 10px;
}

.value {
  display: inline-block;
  margin: 0;
}

.headerTooltip {
  font-size: 14px;
  line-height: 25px;

  a {
    color: white;
  }

  b {
    font-weight: 600;
  }
}
