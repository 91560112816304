.header {
 display: flex;
 flex-direction: row;
 align-items: center;
 gap: 30px;
 align-items: start;
}

.fullscreen {
    height: 100%;
}

.fullscreenButton {
    width: 40px;
    height: 40px;
}