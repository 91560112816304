@import "./../../style/mixin.scss";

.wrapper {
  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    @include blurred-background(30px);
    padding: 7.5px 20px 7.5px 15px;

    .input {
      width: 100%;
      color: white;
      margin: 0 5px;
      font-size: 14px;

      &::placeholder {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .suggestions {
    border-radius: 25px;
    @include blurred-background(30px);

    .suggestion {
      font-size: 14px;
      color: white;
      padding: 5px 5px 5px 15px;
      border: 1px solid transparent;

      &:first-child {
        border-radius: 25px 25px 0 0;
      }

      &:last-child {
        border-radius: 0 0 25px 25px;
      }

      &:hover {
        cursor: pointer;
        color: black;
        background: #00b2f1;
      }
    }
  }
}
