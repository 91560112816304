.tooltip {
  color: white;
  padding: 10px 15px 19px;

  .date {
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, .6);
    font-size: 14px;
    font-weight: 400;
  }

  .header {
    margin-bottom: 15px;
  }

  .item {
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;
  }

  .location {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

}
