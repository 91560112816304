.container {
    display: flex;
    flex-direction: column;
    width: 24%;
    min-width: 17rem;
    height: 396px;
    color: inherit;
    text-decoration: inherit;
    margin: 0.5rem;
}

.project {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.heading {
    display: flex;
    background-color: #c6c6c6;
    height: 67%;
    justify-content: flex-end;
}

.headingActions {
    margin: 0.5rem 0.4rem;
}

.headingActions * {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.optionDeleteIcon {
    fill: #bb3e3e !important;
    cursor: pointer;
}

.optionEditIcon {
    fill: #4a9cb3 !important;
    cursor: pointer;
}

.body {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

.propertyName {
    padding-right: 0.5rem;
}

.propertyValue {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}
