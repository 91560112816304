.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  a {
    text-decoration: none;
    color: white;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.form {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 350px 335px;
  grid-column-gap: 40px;

  .additional {
    .info {
      display: grid;
      grid-template-columns: 20px 1fr;
      align-items: baseline;
      text-align: left;
      grid-column-gap: 5px;
      color: rgba(255, 255, 255, .7);
      p {
        margin: 0;
        .link {
          color: #00B2F1;
        }
      }
    }
  }

  .column {
    margin-top: 50px;
    &.right {
      display: grid;
      grid-column-gap: 20px;
      grid-template-rows: repeat(3, max-content);
      grid-row-gap: 30px;

      .recaptcha {
        height: 60px;
      }
    }
  }
}

.information {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, .1);

  span {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    color: #00B2F1;
  }
}

.fields {
  display: grid;
  grid-row-gap: 20px;
  margin-top: 50px;

  p {
    position: relative !important;
  }
}

.error {
  display: flex;
  color: #ED1C6F;
  margin: 0;
  justify-content: start;
  font-size: 12px;
  width: 280px;
}

.button {
  background: rgba(9, 16, 29, .7);
  border-radius: 25px;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
}
