.title {
    font-size: 24px;
    font-weight: 600;
    color: #1F303D;
    margin: 5px 0 10px;
}

.message {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin: 5px 0 25px;
    text-align: center;
    a {
        text-decoration: none;
        color: #00B2F1;
    }
}

.errorMessage {
    @extend .message;
    color: red;
}
