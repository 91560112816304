.wrapper {
  height: 24px;
  position: relative;
  z-index: 200;

  .menu {
    position: fixed;
    left: 75px;
    top: 85px;
  }
}
