.submitButton {
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans";
    background-color: #00B2F1;
    color: white;
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 30px 0 10px;
}

.loginPageLink a {
    color: #00B2F1;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}
