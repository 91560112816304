.copyToClipboard {
    background: transparent;
    border: transparent;
    cursor: pointer;
    display: flex;
}

:global(div.MuiSnackbarContent-root) {
    background-color: #8bc34a;
    box-shadow: "none";
}
