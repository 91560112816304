.wrapper {
  display: grid;
  padding: 0 15px 15px;
  grid-row-gap: 10px;

  .options {
    display: flex;
    justify-content: end;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding: 5px 0;

    .location {
      overflow: hidden;
      width: 110px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 15px;
    }

    .sliderControl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .dots {
      display: flex;
      justify-content: space-between;
      grid-column-gap: 5px;
      margin: 0 auto;
    }
  }

  .information {
    width: 100%;
    overflow: hidden;
  }

  .items {
    display: flex;
    //justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding-bottom: 5px;
    overflow: hidden;

    &.full {
      width: 310px;
    }

    &.withCut {
      width: 350px;
    }

  }

  .selectedLocation {
    .image {
      width: 310px;
      height: 110px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
}
