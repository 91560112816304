.container {
  display: flex;
  align-items: center;
  flex-grow: 6;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-right: 0.5rem;
    flex-grow: 1;
  }

  .sliderContainer {
    display: flex;
    align-items: center;
    flex-grow: 20;
    span {
      font-style: normal;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }

    span:first-of-type {
      margin-right: 0.5rem;
    }
    span:last-of-type {
      margin-left: 0.5rem;
    }

    .sliderIconContainer {
      position: absolute;
      top: -24px;
    }

    .sliderValue{
      position: absolute;
      top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      -moz-appearance: textfield;
      background: transparent;
      color: white;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.disabled {
        border: none;
      }

      &:hover {
        background: white;
        border: 1px solid black;
        color: #1F303D;
      }
    }
  }
}
