.component {
  position: relative;
}


.wrapper {
  position: absolute;
  right: 0;
  font-size: 14px;
  top: 48%;
  transform: translateY(-56%);
  font-weight: 600;
  display: grid;
  grid-row-gap: 4px;
  padding-right: 3px;
  color: white;

  div {
    display: flex;
    justify-content: end;
  }
}
