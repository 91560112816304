.itemsList {
  height: 100%;

  .searchInput {
    margin: 15px;
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid white;
      color: white;
      opacity: 0.6;
    }
  }

  .header {
    margin-right: 15px;
    display: grid;
    grid-template-columns: 1fr 25px;
    align-items: center;
  }

  .scrollable {
    height: calc(100vh - 104px);
    overflow-y: scroll;
  }

  .singleItem {
    display: flex;
    flex-direction: column;
    width: 310px;
    overflow: hidden;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 15px;
    border: none;
    color: white;
    font-size: 14px;
    padding: 15px;

    &:hover {
      border: 2px solid #00B2F1;
    }

    .row {
      display: flex;
      flex-direction: row;
      margin: 1px 0;

      .icon {
        width: 26px;
        height: 22px;
        text-align: left;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        width: 235px;
        height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .threeDotsMenu {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
      }
      .description {
        opacity: 0.6;
        margin-bottom: 8px;
      }
      .manageLocations {
        margin-left: 10px;
        color: white;
        font-size: 14px;
      }
    }
  }

  .singleProject {
    @extend .singleItem;
    height: 151px;
    background: linear-gradient(90deg, #002E3E 14.09%, rgba(27, 45, 65, 0.24) 100%);
  }

  .singleReport {
    // position: relative;
    @extend .singleItem;
    height: 151px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    // backdrop-filter: blur(30px);
  }

  .emptyListTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-top: 33px;
  }

  .emptyListContent {
    display: flex;
    justify-content: center;
  }

  .addFirstItemImg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 60px;
  }

  // .tooltip {
  //   position: fixed;
  //   background: #1F303D;
  //   text-align: left;
  //   padding: 10px;
  //   z-index: 1000;

  //   .item {
  //     display: block;
  //   }
  // }
}



