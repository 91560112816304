.form {
  display: flex;
  flex-direction: column;
  width: 32rem;
}

.formTop {
  width: 26rem;
}

.formRow {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ffiHeading {
  justify-content: flex-start;
}

.form label {
  font-weight: bold;
}

.form input {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.form input[type='checkbox'] {
  width: 2rem;
}

.form select {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}

.buttonContainer :first-child {
  margin-right: 2rem;
}

hr {
  width: 100%;
  border-top: 1px dashed;
}

.updatePassword {
  position: relative;
  top: -47px;
  left: 537px;
  cursor: pointer;
}