@mixin blurred-background($blur: 40px, $background: rgba(255, 255, 255, 0.1), $alt-background: rgba(58, 74, 92, 0.95)) {

  @supports ((-webkit-backdrop-filter: blur($blur)) or (backdrop-filter: blur($blur))) and (not (background: -webkit-named-image(i))) {
      background: $background !important;
      -webkit-backdrop-filter: blur($blur) !important;
      backdrop-filter: blur($blur);
  }
  @supports (not ((-webkit-backdrop-filter: blur($blur)) or (backdrop-filter: blur($blur)))) or (background: -webkit-named-image(i)) {
    background: $alt-background !important;
  }
}

//Apply to items wrapper
@mixin dropdownAnimation() {

  &.open {
    z-index: 1000;
    animation: show-overflow 3s;
    overflow-y: scroll;
    .item {
      transform-origin: top center;
      animation: rotateX 300ms 60ms ease-in-out forwards;
    }
  }

  &.close {
    .item {
      animation: rotateXBack 300ms 60ms ease-in-out backwards;
    }
    visibility: hidden;
    transition: visibility 0s linear 420ms;
  }


  @keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }

  @keyframes rotateXBack {
    0% {
      opacity: 1;
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
  }

  @keyframes show-overflow {
    from { overflow-y: hidden; }
    to { overflow-y: scroll; }
  }

}
