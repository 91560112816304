.wrapper {
  display: grid;
  grid-template-columns: 75px 180px;
  justify-content: space-between;
  margin: 15px 0 24px 0;

  .labelSkeleton {
    height: 12px;
    border-radius: 25px;
  }

  .selectSkeleton {
    border-radius: 25px;
  }
}

.label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.select {
  width: 180px;
}

.activeItem {
  width: 270px;
}

.activeSelect {
  width: 270px;
  position: absolute;
  right: 0;
  top: -5px;
}

.fixedSelect {
  position: fixed;
  left: 195px;
}
