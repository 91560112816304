.root {
  position: relative;

  width: 100%;
  height: 100%;
}

.root :global(#deckgl-wrapper) {
  pointer-events: none;
}

.root :global(#deckgl-wrapper #view-default-view) > div {
  pointer-events: all;
}

.root :global(.mapboxgl-ctrl-bottom-left) {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.root :global(.mapboxgl-ctrl-bottom-right) {
  :global(.mapboxgl-ctrl.mapboxgl-ctrl-attrib) {
    clear: none !important;
    margin: 0 !important;
    height: 16px;
    font-size: 11px;
    box-shadow: 4px 1px 0px 4px rgba(255, 255, 255, 0.55);
    margin: 0px 0px 3px 0px !important;
    background-color: rgba(255, 255, 255, 0.55) !important;
    line-height: 16px;
  }

  :global(.mapboxgl-ctrl-scale) {
    border-style: none solid solid;
    border-color: #333 !important;
    clear: none !important;
    margin: 0px 0px 3px 0px !important;
    height: 16px;
    line-height: 14px;
    background-color: rgba(255, 255, 255, 0.55) !important;
    box-shadow: -4px 1px 0px 4px rgba(255, 255, 255, 0.55);
  }
}

/*===== reset button =====*/

.resetButton {
  position: absolute;
  top: 122px;
  right: 15px;
  z-index: 8;

  width: 36px;
  height: 36px;
  padding: 0;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(221, 221, 221, 0.3);
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 3px 0 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.resetButton svg {
  width: 18px;
  margin-top: 3px;
  fill: var(--cp-white);
}

.noDataContainer img {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noDataBody {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 101;
  width: 100%;
  height: 100%;
}

.noDataBody a {
  padding: 7px 20px;
  background: #00B2F1;
  border: 1px solid #00B2F1;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  color: #1F303D !important;
}

.noDataBody h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.noDataBody h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  margin: 0;
}

.noDataBody p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.5;
  width: 30rem;
  text-align: center;
}

.noDataBody button {
  background: #00B2F1;
  border: 1px solid #00B2F1;
  border-radius: 4px;
  padding: 7px 20px;
  color: var(--cp-default-icon-color);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
}

.bottomBar {
  position: absolute;
  right: 0;
  z-index: 1000;
  border: 0;
  bottom: 10px;
  transition: .4s;

  &.full {
    width: calc(100% - 54px);
  }

  &.part {
    width: calc(100% - 397px);
  }
}

.navigation {
  position: absolute;
  top: 15px;
  right: 50px;
  z-index: 1;
}
