.form {
  margin-top: 25px;
}

.actions {
  display: grid;
  grid-row-gap: 10px;
  margin-top: 30px;
}

.error {
  margin-top: 20px;
  color: #ED1C6F;
}

.button {
  background: rgba(9, 16, 29, .7);
  border-radius: 25px;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  width: 100%;
}

.textButton {
  color: white;
  text-decoration: none;
}
