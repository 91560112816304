.button {
  display: flex;
  background: transparent;
  box-shadow: 0 8px 16px rgba(31, 48, 61, 0.15);
  color: white;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  height: 38px;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 35px;
  padding: 7px 20px;
  margin: 0;
}

.button.animation {
  animation: wiggle 5s 5s linear infinite;
}

.button svg {
  margin-right: 8px;
}

.primary {
  background-color: #334254;
  border: 1px solid rgba(255, 255, 255, .2);
}

.filled {
  background: #00B2F1;
  color: #1F303D;
}

.disabled {
  opacity: 0.6;
  /* background-color: var(--cp-color-disabled); */
}

.icon {
  margin-right: 10px;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  10% {
    transform: rotateZ(-15deg);
  }
  15% {
    transform: rotateZ(10deg);
  }
  17% {
    transform: rotateZ(-10deg);
  }
  19% {
    transform: rotateZ(6deg);
  }
  23% {
    transform: rotateZ(-4deg);
  }
  40% {
    transform: rotateZ(0);
  }
}
