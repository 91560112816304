.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  width: 100%;

  .color {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }

  .item {
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;

    p {
      margin: 0;
    }
  }
}
