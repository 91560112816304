.wrapper {
  position: relative;
  top: -30px;
  .circle {
    position: relative;
    width: 120px;
    height: 170px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      width: 95px;
      height: 85px;
      bottom: 0%;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }

    .image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 3px solid white;
      img {
        object-fit: cover;
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }


  .title {
    position: absolute;
    right: 50%;
    white-space: nowrap;
    transform: translateX(50%);
    font-weight: 600;
    color: white;
    margin: 30px 0 0 0;
    text-align: center;
  }
}

