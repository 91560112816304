@import "../../../style/variables.css";

.button {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    background-color: var(--color-primary);
    color: var(--color-text-light);
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
}

.disabled {
    background-color: var(--color-disabled);
    cursor: unset;
}

.secondary {
    background-color: var(--color-disabled);
}

.warning {
    background-color: var(--color-invalid);
}
