// =============== Fonts ===============
$font-color-primary: #FFF;
$font-color-secondary: rgba(255, 255, 255, 0.6);
$font-color-error: #FA629E;

$font-weight-normal: 400;
$font-weight-bold: 600;

// =============== Visual components - buttons, switchers, icons, sliders... ===============
$visuals-color: #FFF;
$visuals-color-active: #1f303d;

$visuals-background-color: rgba(9, 16, 29, 0.3);
$visuals-background-color-alternative: #354356;
$visuals-background-color-active: #00b2f1;
$visuals-background-blur: 15px;
$visuals-line-color: rgba(255, 255, 255, 0.2);
