.buttonContainer {
  display: flex;
  align-items: center;
}

.automaticUpdates {
  display: flex;
  align-items: center;

  label {
    margin-right: 30px;
  }

}