/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & +.slider {
        background-color: #00B2F1;
        border-color: #00b2f1;
        &:before {
          background: #1F303D;
          transform: translateX(11px);
          height: 14px;
          width: 14px;
          bottom: 3px;
        }
      }
    }

    &:focus {
       & +.slider {
         box-shadow: 0 0 1px #2196F3;
       }
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: .4s;
  transition: .4s;

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.slider {
  &:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

