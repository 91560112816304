@import "../../../style/variables.css";

.logo {
    display: flex;
    flex-direction: column;
    height: var(--top-bar-height);
    padding: 16px;
    width: 100%;
    background-color: #45bcd3;
}

.svg {
    fill: #fff;
}
