.information {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 14px;

  .value {
    font-weight: 600;
  }
  p {
    margin: 0 0 10px 0;
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  height: 75px;

  .chart {
    position: absolute;
    top: -27px;
    width: 100%;
  }
}
