.container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 28px;
    width: 115px;
  }

  .sourceLocationDropdown {
    width: 155px;
  }

  .selectedItemClassName {
    color: #FFFFFF;
  }

  .labelSkeleton {
    width: 80px;
  }

  .dropdownSkeleton {
    width: 170px;
  }
}
