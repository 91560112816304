.information {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-top: 1px solid rgba(255, 255, 255, .1);

  span {
    font-size: 14px;
  }

  a {
    color: #00B2F1;
  }
}

.question {
  padding-right: 5px;
}

