.header {
  justify-content: space-between;
  grid-column-gap: 40px;
  align-items: center;
  margin-bottom: 20px;

  &.percentage {
    grid-template-columns: 295px 1fr 305px 160px;
    display: grid;
  }

  &.absolute {
    display: grid;
    grid-template-columns: 295px 1fr 165px;
  }

    .items {
      display: flex;
      grid-column-gap: 30px;
    }
  
    p {
      margin: 0;
      padding: 0;
    }
  
    .information {
      color: white;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  
    .subTitle {
      color: rgba(255, 255, 255, 0.6);
    }
  
    .value {
      display: inline-block;
      margin-left: 2px;
    }
}

