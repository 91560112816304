.column {
  position: absolute;
  top: 0;
  height: 100%;
  width: 200px;

  .item {
    position: absolute;
    top: 25px;
    transform: translateY(50px);
  }

  .title {
    position: absolute;
    font-weight: 600;
    color: white;
    top: 39px;
    left: 59%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
  }

  &.before {
    right: 47px;
  }

  &.after {
    left: -117px;
  }
  &.during {
    left: 0px;
    top: 50px;
  }


  &.columnIndex0 {
    left: 0px;
    top: 50px;
  }

  &.columnIndex1 {
    left: 450px;
    top: 50px;
  }

  &.columnIndex2 {
    left: 900px;
    top: 50px;
  }
}