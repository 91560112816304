.wrapper {
  display: inline-flex;
  grid-column-gap: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px rgba(19, 29, 37, 0.4);
  backdrop-filter: blur(35px);
  border-radius: 5px 5px 0px 0px;
  padding: 4px 10px 4px 10px;

  .icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(9, 16, 29, 0.3);
  }
}
