.templateFile {
    margin-top: 8px;
    margin-bottom: 8px;
}

.templateFile a {
    text-decoration: none;
    color: var(--color-primary);
}

.resultContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.singleResult {

}

.singleResultSuccess {
    color: var(--color-green);
}

.singleResultError {
    color: var(--color-invalid);
}
