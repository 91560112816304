.panel {
  display: flex;
  align-items: center;
  margin: 15px 13px;
  height: 18px;

  .backButton {
    cursor: pointer;
    margin-right: 5px;
  }

  .item {
    display: flex;
    align-items: center;
    .icon {
      svg {
        height: 18px;
        width: 18px;
        path {
          fill: var(--cp-active-fill-color);
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    .arrow {
      margin: 0 10px;
    }
    .information {
      font-size: 16px;
      line-height: 1;
      margin: 0;
    }
  }

  .reportItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .reportItem {
    justify-content: space-between;
    width: 100%;
  }

  .skeleton {
    width: 100%;
  }

  .plusButton {
    display: flex;
    margin-left: auto;
  }

  .tooltipIconContainer, .settings {
    position: relative;
    z-index: 2;
  }

  .skeletonIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .tooltipIcon {
    display: block;
    margin: 3px 6px 0 7px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: white;
      }
    }
  }
}
