.usersContainer {
 display: flex;
 flex-direction: row;
 align-items: center;
 margin-bottom: 1rem;
}

.usersContainer label {
 font-weight: bold;
 margin-right: 1rem;
}

.usersContainer select {
 width: 17rem;
 padding: 9px;
 border: 1px solid #ccc;
 border-radius: 4px;
}