.submitButton {
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans";
    background-color: #00B2F1;
    color: white;
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 30px 0 10px;
}

.loginPageLink {
    display: flex;
    background: transparent;
    box-shadow: 0 8px 16px rgba(31, 48, 61, 0.15);
    color: white;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    height: 38px;
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 35px;
    padding: 7px 20px;
    margin: 0;

    a {
        text-decoration: none;
        color: #FFFFFF;
    }

    &.filled {
         background-color: #334254;
         border-width: 0;
     }
}