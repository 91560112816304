.information {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;

  .rank {
    margin-bottom: 10px;
    h4 {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      line-height: 1;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      margin: 5px 0 0 0;
      line-height: 1;
    }
  }

  .wrapper {
    position: relative;
    width: 305px;
    height: 245px;
    overflow: hidden;

    .image {
      position: absolute;
      top: 53%;
      left: 50%;
      width: 300px;
      transform: translate(-50%, -50%);
    }
  }

  .name {
    margin: 10px 0 0 0;
  }
}
