@import "../../../../common/style/variables.scss";

.title {
    font-size: 22px;
    font-weight: 600;
    color: $font-color-primary;
    text-align: center;
    margin: 10px 0 10px;
}

.subtitle {
    color: $font-color-primary;
    text-align: center;
    margin: 0 0 20px;
}

.formRow {
    display: grid;
    grid-template-columns: 150px auto;
    flex-direction: row;
    padding: 10px 25px;
    .left {
        color: $font-color-secondary;
    }
}
.error {
    color: $font-color-error;
    text-align: center;
}

.buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
}