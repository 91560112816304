.logo {
  display: grid;
  justify-content: center;
  color: white;

  svg {
    margin: 0 auto;
  }

  span {
    margin-top: 2px;
    display: block;
  }
}
