.field {
  width: 384px;
}

.underlined {
  position: relative;
  margin-bottom: 20px;

  &:after {
    content: ' ';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, .1);
  }
}

.noUnderlined {
  display: grid;
  grid-row-gap: 25px;
  padding-top: 20px;
}

.message {
  margin-top: 20px;
  font-size: 12px;

}

.error {
  color: #ED1C6F;
}

.success {
  color: forestgreen;
}
