.addPoiForm {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.addPoiMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.addPoiMessageError {
    color: var(--color-invalid);
}

.addPoiMessageSuccess {
    color: var(--color-green);
}

.addPoiButtonContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}


.formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.polygonText {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.fieldName {
    display: flex;
    font-weight: bold;
    text-align: left;
    width: 40%;
    flex-direction: row;
    align-items: center;
}

.input {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    width: 100%;
}

.valueContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textarea {
    display: flex;
    flex-direction: column;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    resize: none;
    height: 148px;
    width: 100%;
    margin-bottom: 1rem;
}
