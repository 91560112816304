@import '../../../common/style/mixin.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -7px;
  position: relative;

  button {
    padding: 0
  }

  .icon {
    height: 32px;
    width: 32px;
    display: flex;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 1px rgba(9, 16, 29, 30%);
    backdrop-filter: blur(35px);
    cursor: pointer;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
      background: #00B2F1;

      path {
        fill: #1F303D;
      }
    }
  }

  .menu {
    position: absolute;
    z-index: 99;
    border-radius: 5px;
    display: flex;
    top: 35px;
    align-items: center;
    width: 253px;
    height: 82px;
    justify-content: space-around;
    box-shadow: 0 0 1px rgba(9, 16, 29, 30%);
    clip-path: path('M0 12.9394C0 11.2825 1.34315 9.93939 3 9.93939H118.874C119.827 9.93939 120.723 9.48701 121.288 8.7205L127.722 0L134.156 8.7205C134.722 9.48701 135.618 9.93939 136.57 9.93939H250C251.657 9.93939 253 11.2825 253 12.9394V79C253 80.6569 251.657 82 250 82H3C1.34314 82 0 80.6569 0 79V12.9394Z');
    @include blurred-background(80px);

    .input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    button {
      width: 100%;
      height: 100%;
      padding: 12px 0 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;


      .icon {
        width: 26px;
        height: 26px;
        background: rgba(9, 16, 29, 0.6);
      }

      &:hover,
      &.active {
        .icon {
          background: #00B2F1;

          path {
            fill: #1F303D;
          }
        }
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        color: #FFFFFF;
        opacity: 0.6;
        margin-top: 2px;
      }

      &:disabled, &:disabled &:hover {
        cursor: unset;

        .icon {
          cursor: unset;
          background: rgba(9, 16, 29, 0.4);
          path {
            fill: #FFFFFF;
            opacity: 0.4;
          }

        }

        span {
          opacity: 0.3;
        }
      }
    }
  }
}