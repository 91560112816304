.modal {
    min-width: 23vw;
    min-height: 10vh;
    background-color: var(--color-background);
    display: flex;
    position: absolute;
    top: 30vh;
    left: 37vw;
    outline: none;
    border: 1px solid var(--color-separator);
    border-radius: 4px;
    max-height: 80vh;
    padding: 10px;
    border: 2px solid #ae2634;
    border-radius: 15px;
}

.modalContent {
    padding: 8px;
    flex: 1;
}

.deleteOptionIcon {
    fill: #bb3e3e !important;
}

.title {
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.deleteButton {
    /*fill: #bb3e3e !important;*/
    padding-right: 8px;
}

.cancelButton {

}