.row {
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;

 .line {
   display: flex;
   align-items: center;
   color: white;

   .name {
     margin: 0;
     text-overflow: ellipsis;
     width: 130px;
     overflow-x: hidden;
     white-space: nowrap;
   }

   svg {
     margin-right: 8px;
   }
 }
}
