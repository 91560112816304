@import '../style/mixin.scss';
@import '../style/variables.scss';

.locationsDropdown {
  position: relative;
  display: flex;
  border-bottom: 1px solid #556579;
  width: 100%;

  .activeLocations {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    > button {
      padding: 6px 10px;
      cursor: pointer;
      path {
        fill: #00B2F1;
      }
    }
  }

  .name {
    padding: 6px 10px;
  }

  .locationsList {
    position: absolute;
    top: 40px;
    left: 0;
    width: 360px;
    @include blurred-background(80px);
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
    z-index: 1000;

    .title {
      padding: 15px 20px;
    }

    .list {
      max-height: 295px;
      overflow: auto;
    }

    .groupTitle {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      opacity: 0.6;
    }

    .locationRow {
      display: flex;
      justify-content: space-between;
      padding: 6px 20px;

      .icon {
          svg {
          margin-right: 8px;
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid #556579;
      margin-top: 20px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      padding: 20px;
      gap: 20px;
      justify-content: flex-end;
    }

    .reportLocations {
      height: 100%;
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}
