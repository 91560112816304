.header {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;
}

.optionEditIcon {
 fill: #4a9cb3 !important;
 cursor: pointer;
}

.optionEditIcon:visited {
 fill: #4a9cb3 !important;
 cursor: pointer;
}

.usersTable {
 width: 100%;
 border-collapse: collapse;
}

.usersTable td, .usersTable th {
 text-align: left;
 padding: 0.30rem 0.25rem;
}

.usersTable tr:nth-child(even) {
 background-color: #eaeaea;
}

.buttonContainer {
 display: flex;
 justify-content: flex-end;
 margin-bottom: 1rem;
}
