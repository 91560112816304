@import '../../style/variables.scss';

.buttonTooltip {
    z-index: 2022 !important;
    &>div {
        background-color: #354558;
        font-size: 14px;
        color: $font-color-secondary;
        padding: 5px 10px;
        &>span::before {
            background-color: #354558;
        }
    }
}