.filters {
  display: flex;
  justify-content: start;
  grid-column-gap: 15px;
  height: 35px;

  .item {
    width: 200px;
  }

  .hidden {
    visibility: hidden;
  }
}
