.tableRow {
  padding: 20px 0;
  border-bottom: 1px solid #304154;
  display: flex;
  grid-template-columns: 250px auto;
  flex-direction: row;

  .nameContainer {
    display: flex;
    flex-direction: row;
    width: 250px;
    align-items: center;

    .tooltipIconContainer {
      position: relative;
      z-index: 2;
    }

    .skeletonIcon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .tooltipIcon {
      display: block;
      margin: 3px 6px 0 7px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: white;
        }
      }
    }
  }

  .nameField {
    color: white;
    opacity: 0.6;
  }

  &.noneBorder {
    border: none;
  }

  .iconField {
    fill: #f5f5f5 !important;
  }

  .valueField {
    color: white;
    a {
      color: #00BDF2;
      text-decoration: none;
    }
  }
}
