.table {
  height: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    grid-column-gap: 15px;
  }
}