.container {
    display: flex;
    flex-direction: column;
    background-color: #838383;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    color: var(--color-text-light);
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
}
