.item {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 12.5px 0;
  justify-content: space-between;

  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, .1);
  }

  .wrapper {
    display: flex;
    grid-column-gap: 5px;

    .content {
      display: flex;
      grid-column-gap: 5px;

      .title {
        color: #FFFFFF;
      }

      .subtitle {
        color: rgba(255, 255, 255, .6)
      }

      p {
        margin: 0;
      }
    }

  }

  .menuIcon {
    cursor: pointer;
    padding-right: 15px;
  }

  .menu {
    position: absolute;
    right: 8px;
    top: 6px;
    z-index: 100;
  }
}
