@import '../../../common/style/mixin.scss';
@import '../../../common/style/variables.scss';

.locationsCard {
  position: relative;
  z-index: 3;

  &.shadow {
    box-shadow: 0px 10px 15px rgba(19, 29, 37, 0.3);
  }

  .address {
    margin: 15px;
    color: $font-color-secondary;
    font-size: 14px;
  }

  .locationsDropdown {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 15px;
    border-bottom: 1px solid #556579;

    .activeLocations {
      display: flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;
      > button {
        padding: 6px 10px;
        cursor: pointer;
        path {
          fill: #00B2F1;
        }
      }

    }

    .name {
      padding: 6px 10px;
    }

    .locationsList {
      position: absolute;
      top: 40px;
      left: 20px;
      z-index: 20;
      width: 360px;
      @include blurred-background(80px);
      border-radius: 5px;
      box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
      position: fixed;
      top: 156px;
      left: 70px;
      z-index: 1000;

      .title {
        padding: 15px 20px;
        text-overflow: ellipsis;
      }

      .searchInput {
        margin: 15px;
        input {
          border: none;
          border-bottom: 1px solid white;
          color: white;
          opacity: 0.6;
        }
      }

      .list {
        max-height: 295px;
        overflow: auto;
      }

      .groupTitle {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        opacity: 0.6;
      }

      .locationRow {
        display: flex;
        justify-content: space-between;
        padding: 6px 20px;

        .icon {
            svg {
            margin-right: 8px;
          }
        }
      }

      hr {
        border: none;
        border-top: 1px solid #556579;
        margin-top: 20px;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        padding: 20px;
        gap: 20px;
        justify-content: flex-end;
      }

      .reportLocations {
        height: 100%;
        max-height: 400px;
        overflow-y: scroll;
      }
    }

    .skeleton {
      width: 100%;
      .skeletonField {
        height: 10px;
        width: 100%;
      }
    }
  }

  .selectedLocationsBar {
    margin: 15px;

    .singleLocation {
      color: white;
      background: transparent;
      border: 1px solid white;
      opacity: 0.6;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;

      &_selected {
        @extend .singleLocation;
        border: 2px solid #00b2f1;
      }
    }
  }
}



