.table {
  background: linear-gradient(90deg, rgba(27, 45, 65, 0.88) 41.01%, rgba(27, 45, 65, 0.15) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  max-height: calc(100vh - 170px);
  overflow-y: auto;

  .blueTitle {
    margin: 20px 30px 30px;
    color: #00B2F1;
    font-size: 16px;
    font-weight: 600;
  }
  .title {
    margin: 20px 30px 5px;
    color: white;
    font-size: 16px;
    font-weight: 600;

  }
  .subtitle {
    margin: 0 30px;
    color: white;
    opacity: 0.6;
    font-size: 14px;
    white-space: pre;
  }
  .tableContent {
    margin: 0;
    padding: 30px;
  }
}
