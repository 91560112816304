@import '../../../../../common/style/mixin.scss';
.tooltip {
  @include blurred-background(60px, transparent, rgba(87, 101, 119, 0.95));
  position: fixed;
  z-index: 100;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 20px;
  display: none;
}
