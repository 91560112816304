.information {
  text-align: left;

  &.clickable {
    cursor: pointer;
  }

  &.right {
    &.information {
      text-align: left;
    }
  }

  &.left {
    &.information {
      text-align: right;
    }
  }

  .value {
    color: white;

    &.withContribution {
      display: flex;
      .contribution {
        font-size: 10px;
        align-self: end;
        margin-bottom: 2px;
        margin-left: 5px;
      }
    }
  }

  .circle {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
  }

  .row {
    display: flex;
    align-items: center;

    &.advanced {
      grid-column-gap: 5px;
      justify-content: start;
    }

    &.information {
      justify-content: end;
    }

    .label {
      color: rgba(255, 255, 255, .6);
      margin: 0;
      font-size: 14px;
    }
  }
}
