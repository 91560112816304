.container {
  display: flex;
  align-items: center;
  padding: 3px 3px 3px 3px;

  border-radius: 25px;
  height: 30px;
  cursor: pointer;


  &.frame {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);

    &.dark {
      background: rgba(9, 16, 29, 0.3);
    }
  }

  &.optionFrame {
    span {
      &.disabled {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(15px);
      }
    }
  }

  span {
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #00B2F1;

    &.disabled {
      background: transparent;
    }

    &:first-of-type {
      margin-right: 8px;
    }

    &:disabled {
      cursor: unset;
    }
  }
}
