@import '../../../../common/style/mixin';

.menu {
  position: relative;
  z-index: 10;
  padding: 15px 20px;
  width: 390px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    @include blurred-background(80px, rgba(255, 255, 255, 0.3), rgba(79, 92, 109, 1));
    border-radius: 5px;
  }

  .title {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
  }

  .block {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding-bottom: 20px;
    margin-top: 15px;
    .fields {
      display: grid;
      grid-row-gap: 10px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actions {
    display: flex;
    grid-column-gap: 15px;
    justify-content: end;
    margin-top: 20px;
  }

  .field {
    display: grid;
    grid-template-columns: 105px 220px;
    grid-column-gap: 15px;
    align-items: center;
    label {
      font-size: 14px;
      color: rgba(255, 255, 255, .6);
    }
  }
}
