.map {
  position: relative;
  width: 100%;
  height: 100%;

  .search {
    position: absolute;
    top: 24px;
    left: 17px;
    right: 17px;
    z-index: 11;
  }

  .actions {
    position: absolute;
    top: 154px;
    left: 20px;
    z-index: 10;
  }
  .navigation {
    position: absolute;
    top: 65px;
    left: 20px;
    z-index: 1;
  }
}

