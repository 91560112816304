@import '../../../common/style/mixin.scss';


.dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 30.5px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: white;
    cursor: pointer;

    .values {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    > p {
      padding: 6px 10px;
      margin: 0;
      font-size: 14px;
      width: calc(100% - 13px);
    }

    > span {
      path {
        fill: #00B2F1;
      }
    }
  }

  .wrapper {
    position: absolute;
    z-index: 20;
    width: 360px;
    @include blurred-background(80px);
    border-radius: 5px;
    top: 37px;
    left: 0;
    width: 100%;

    .list {
      height: 215px;
      overflow: hidden;
      overflow-y: scroll;
    }

    .title {
      padding: 15px 20px;
    }

    .selectAll {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      color: rgba(255, 255, 255, .6);
    }

    .buttons {
      display: flex;
      flex-direction: row;
      padding: 20px;
      gap: 20px;
      justify-content: flex-end;
    }

    &.close {
      visibility: hidden;
      opacity: 0;
    }
  }
}

