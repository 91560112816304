@import '../../common/style/mixin.scss';

.modal {
  @include blurred-background(40px, rgba(255, 255, 255, 0.1), rgba(74, 92, 114, 0.95));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
  border-radius: 5px;
  text-align: center;
  color: white;
  width: max-content;

  .wrapper {
    padding: 30px 30px 20px 30px;
    .company {
      span {
        display: block;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }

    .footer {
      display: flex;
    }
  }
}

.topSpace {
  margin-top: 20px!important;
}
