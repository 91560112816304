@import '../../../common/style/mixin.scss';
@import '../../../common/style/variables.scss';

.dropdownContainer {
  position: relative;
  width: 100%;
  height: 30px;
}

.selectedItem {
  padding: 6px 6px 4px 6px;
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  transition: all linear 540ms;

  span {
    padding-right: 10px;
  }
  path {
    fill: #00B2F1;
  }

  &.disabled {
    color: $font-color-secondary;
    path {
      fill: $font-color-secondary
    }
  }
}

.selectedItem_closed {
  color: #FFFFFF;
}

.selectedItem_open {
  @include blurred-background(20px, rgba(255, 255, 255, 0.3), rgba(79, 92, 109, 1));
  border-bottom: unset;
  margin-bottom: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &.selectedItem_empty {
    min-height: 30px;
  }

  svg {
    transform: rotate(180deg);
  }
}
