.container {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.addPoiContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.poiList {
    flex: 2;
    margin-right: 32px;
}

.deletePoisMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.deletePoisMessageError {
    color: var(--color-invalid);
}

.deletePoisMessageSuccess {
    color: var(--color-green);
}

.errorMessage {
    display: flex;
    flex-direction: column;
    color: var(--color-invalid);
    margin-top: 4px;
}
