@import "../../../../style/mixin";

.popup {
  color: rgba(255, 255, 255, .6);
  padding: 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.45);
  @include blurred-background(40px);
  border-radius: 5px;

  .title {
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: white;
    font-size: 16px;
    margin-bottom: 20px;

    .icon {
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 5px;

    .item {
      display: flex;
      grid-column-gap: 10px;

      .category, .value {
        margin: 0;
      }

      .category {
        width: 190px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .value {
        width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
