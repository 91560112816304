@import "../../style/mixin.scss";

.container {
  @include blurred-background(80px);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1000;
  min-width: 672px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(19, 29, 37, 0.3);
  border-radius: 5px;
  display: flex;
  padding: 15px 20px 20px 20px;
  flex-direction: column;
  height: 210px
}

.headerContainer {
  width: 100%;
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    opacity: 0.6;
  }
}

.yearsContainer {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 632px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
  margin-top: 20px;
  margin-bottom: 15px;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
  }

  .datesContainer {
    min-width: 158px;
    margin-right: 80px;

    &:last-child {
      margin-right: 0;
    }

    .yearContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 17.5px;

      &.disabled input {
        opacity: 0.5;
      }

      label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: end;
  margin-top: 20px;

  button:last-of-type {
    background: rgba(9, 16, 29, 0.4);
    margin-left: 1rem;
  }
}
