.container {
  display: flex;
}

.formContainer {
  max-width: 24rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.formRow {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.requirementsContainer {
  margin-left: 2rem;
}

.requirementsList {
  font-size: 0.95rem;
}

.label {
  font-weight: bold;
}

.input {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: var(--value-row-width);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}