.container {
  overflow: visible;
  height: calc(100vh - 48px);

  .scrollingWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 450px);
    overflow-y: auto;
    width: 340px;

    //Needs to be here for cutting fixed popups
    //clip: rect(-250px, 99999px, auto, auto);
    position: absolute;
  }

  .pickerAndExport {
    display: grid;
    align-items: center;
    grid-template-columns: 280px 25px;
    justify-content: space-between;
    margin: 0 15px;

    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

}
