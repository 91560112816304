.dot {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background: #00B2F1;
    border: 0;
  }
}
