.container {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 387px;
  width: calc(100% - 59px);
  height: 95%;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid transparent;
  border-radius: 5px;
}
