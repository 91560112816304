@import "../../../style/variables.scss";

.visualizationModeToggle {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 3px;
  border-radius: 15px;
  gap: 5px;
  background: $visuals-background-color;
}
