.popup {
  width: 290px;

  .cross {
    display: flex;
    justify-content: end;
  }

  .information {
    h4 {
      margin: 0;
      color: white;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .preview {
    height: 110px;
    width: 290px;
    margin-bottom: 10px;
    margin-top: 5px;
    object-fit: cover;
  }
}
