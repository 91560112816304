@import '../../../common/style/mixin.scss';

.catchmentMenu {
  position: fixed;
  top: 755px;
  left: 389px;
  z-index: 99;
  @include blurred-background(80px);
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(19, 29, 37, 0.3);
  &.bigger {
    height: 480px;
  }
  &.smaller {
    height: 315px;
  }
  

  hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
    }

    .icon {
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 12px 20px 10px;

    .rowHeader {
      color: rgba(255, 255, 255, 0.6);
      width: 140px;
      font-size: 14px;
    }
    .content {
      margin-left: 20px;
      width: 310px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    justify-content: flex-end;
  }
}



