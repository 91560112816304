@import "../../../../common/style/variables.scss";

.container {
  color: $font-color-primary;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    :first-child {
      margin-right: 15px;
    }
  }
}