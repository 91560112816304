.createSidebarTitle {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #454545;
}

.createSidebarTitle:before,
.createSidebarTitle:after {
  content: "";
  display: block;
  top: 50%;
  width: 100%;
  height: 0;
  max-height: 0;
  border-top: 1px solid #afafaf;
  margin: -1rem;
  z-index: 98;
}

.createSidebarTitle h4 {
  display: block;
  width: 150%;
  text-align: center;
  flex-grow: 1;
  z-index: 99;
  background-color: var(--color-background);
  font-weight: bold;
}

.createSidebarSearchField {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.createSidebarSearchField + .createSidebarSearchField:before {
  display: block;
  position: absolute;
  top: -1.6em;
  width: 100%;
  text-align: center;
  content: "— or —";
  color: #bbbbbb;
  text-transform: uppercase;
  font-size: 12px;
}

.createSidebarSearchFieldValue {
  position: relative;
  font-size: 14px;
  width: 100%;
  line-height: 3em;
  padding: 0 15px;
  padding-left: 35px;
  z-index: 99;
  outline: none;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.createSidebarSearchFieldValue:active,
.createSidebarSearchFieldValue:focus {
  border: 1px solid #45bbd3;
}

.createSidebarSearchFieldLabel {
  display: flex;
  left: 0;
  position: absolute;
  font-size: 20px !important;
  z-index: 99;
  margin: 0 10px;
  color: #bbbbbb;
}

.createSidebarSearchFieldValue:active + .createSidebarSearchFieldLabel,
.createSidebarSearchFieldValue:focus + .createSidebarSearchFieldLabel {
  color: #45bbd3;
}

.createSidebarSearchFieldValueHasValue {
  border: 1px solid #484848;
}

.createSidebarSearchFieldValueHasValue + .createSidebarSearchFieldLabel {
  color: #484848;
}

.createSidebarSearchFieldOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: -3px;
  padding-top: 2px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  z-index: 100;
}

.createSidebarSearchFieldOptionsItem {
  color: #484848;
  width: 100%;
  font-size: 14px;
  line-height: 1.3em;
  padding: 10px 20px;
  cursor: pointer;
}

.createSidebarSearchFieldOptionsItem:hover {
  background-color: #d1d1d1;
}
