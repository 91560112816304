@import '../../../../common/style/variables.scss';
.wrapper {
    display: flex;
    flex-direction: row;

    .title {
        width: 100px;
        display: flex;
        align-items: center;
    }
    .dropdown, .toggle {
        width: 100px;
        .toggleLabel {
            padding-right: 10px;
        }
    }
}

.settingsBox {
    margin: 20px 0;
    .row {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin: 10px 0;
        .header {
            width: 250px;
            color: $font-color-secondary;
            display: flex;
            align-items: center;

            svg {
                width: 16px;
                margin: 5px 0 0 10px;
            }
        }
        .component {
            width: 350px;
            display: flex;
            align-items: center;
        }
    }
}
