@import "../../style/variables.scss";

.container {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;

  .switcher {
    display: flex;
    align-items: center;
    background: rgba(9, 16, 29, 0.6);
    backdrop-filter: blur(15px);
    border-radius: 25px;
    padding: 3px;
    grid-column-gap: 10px;
    cursor: pointer;
  }

  button {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: $visuals-background-color-active;
    border: none;
    cursor: pointer;
    padding: 0;

    &.disabled {
      background: transparent;
    }

    &:disabled {
      background: var(--color-disabled);
      cursor: unset;
      border: 1px solid var(--color-side-bar-background);
      &.disabled {
        border: none;
      }
    }
  }

  label {
    font-style: normal;
    font-size: 14px;

    &:first-of-type {
      width: 41px;
    }

    &.active {
      font-weight: 600;
    }
  }
}
