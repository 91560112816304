.container {
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;

    .loginBox {
        background-color: #ffffff;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 45px 30px;
        box-shadow: 0 -15px 100px 20px rgba(0,0,0,.25);
        border-radius: 4px;

        form {
            width: 330px;
            text-align: center;
        }
        .companyName {
            margin-bottom: 10px;
        }
    }
}
