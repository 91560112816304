.formContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;

    --value-row-width: 60%;
}

.delimiter {
    height: 2px;
    width: 100%;
}

.categoryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.valueContainer {
    display: flex;
    flex-direction: column;
    width: var(--value-row-width);
}