.lines {
  position: absolute;
  top: 0;
  height: 500px;
  width: 500px;

  .line {
    width: 175px;
  }

  svg {
    width: 500px;
    height: 500px;
  }

  path {
    fill: none;
  }

  &.right {
    right: 73px;
  }

  &.left {
    left: 121px;
  }
}
