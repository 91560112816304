.item {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  padding: 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: .2s linear;

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  &:hover {
    background: #00B2F1;
    color: #1F303D;

    path {
      fill: #1F303D;
    }
  }

  p {
    margin: 0;
  }
}
