@import "../../style/variables.scss";

.actions {
  position: absolute;
  top: 141px;
  right: 8px;
  z-index: 8;
  display: grid;

  button {
    width: 42px;
    height: 42px;
    padding: 0;

    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 3px 0 2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(221, 221, 221, 0.3);
    cursor: pointer;

    &.actionButton_last {
      border-radius: 0 0 4px 4px;
      margin-bottom: 10px;
    }

    &.export {
      border-radius: 20px;
      border-color: transparent;
      svg {
        margin-top: 5px;
      }
    }
  }
}
