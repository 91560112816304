.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
  }

  /* Wokrs for Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.custom-transparent-scrollbar {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 50px;
  }

  /* Wokrs for Firefox */
  scrollbar-color: rgba(255, 255, 255, 0) transparent;
}

.remove-map-logo {
  .mapboxgl-ctrl {
    display: none!important;
  }
}
