.profile span {
  font-size: 12px;
  font-weight: 600;
}

button:hover .profile {
  background-color: #1f303d;
  color: white;
}

.big {
  display: flex;
  width: 75px;
  height: 75px;
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  align-items: center;
  position: relative;
  color: #FFFFFF;
  box-shadow: 0 0 60px rgba(19, 29, 37, 0.3);
  border-radius: 50px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.space {
    margin-bottom: 20px;
  }
}

.image {
  object-fit: cover;
  width: 75px;
  height: 75px;
  border-radius: 50px;
}
