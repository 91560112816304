.container {
  color: rgba(255, 255, 255, .7);

  .titleContainer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a, a:visited, a:hover {
      color: #00B2F1;
    }

    .importLocationsContainer {
      display: flex;
      align-items: center;

      .tooltipIconContainer {
        position: relative;
        z-index: 2;
      }

      .tooltipIcon {
        display: block;
        margin: 3px 6px 0 7px;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;

          path {
            fill: white;
          }
        }
      }
    }
  }
}