.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;

  padding: 150px 100px;

  background-color: rgba(0, 0, 0, 0.5);
}

.window {
  display: flex;
  width: 100%;
  height: 100%;
}

.map {
  width: 80%;
}

.sidebar {
  width: 20%;
  min-width: 400px;
}
