.root {
  margin: 20px 0;
  backdrop-filter: blur(15px);
}

.table {
  width: 100%;
  border-spacing: 1px;

  .header {
    .headerCell {
      padding: 8px;
    
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
    }
    .headerContent {
      display: flex;
      justify-items: flex-start;
      align-items: center;
      gap: 6px;
    }
  }

  .tableBody {
    tr:hover {
      color: rgba(255, 255, 255, 0.6) !important;
      text-decoration: none;
    }
  }
  
}




/*===== Sort button =====*/

.sortButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.sortButton__icon {
  --top-arrow-color: #FFFFFF;
  --bottom-arrow-color: #FFFFFF;

  display: block;
  width: 24px;
}

.sortButton__icon_asc {
  --bottom-arrow-color: var(--cp-active-fill-color);
}

.sortButton__icon_decs {
  --top-arrow-color: var(--cp-active-fill-color);
}

/*===== Item =====*/

.item {
  background: rgba(255, 255, 255, 0.1);

  font-size: 14px;
  color: #FFFFFF;
}

.item:nth-child(odd) {
  background: transparent;
}

.itemName {
  padding: 8px;

  vertical-align: top;
  font-weight: 600;

  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.itemName ~ td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.itemOption {
  padding: 8px;
}

.itemOptionValue {
  padding: 8px;

  text-align: right;
}

/*===== Item total =====*/

.itemTotal {
  font-size: 14px;
  font-weight: 600;

  background: transparent;
}

.itemTotalCell {
  padding: 8px;
}

.itemTotalValue {
  padding: 8px;

  text-align: right;
}

/*===== Footer =====*/

.footer {
  font-weight: 600;
}

.footerCell {
  padding: 8px;

  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.footerValue {
  padding: 8px;

  text-align: right;

  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

/*===== button  =====*/

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin: 20px 0 0;
  padding: 7px 20px;
  box-sizing: border-box;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  cursor: pointer;
}
