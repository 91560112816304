@import '../../../common/style/mixin.scss';
.wrapper {
  width: 100%;
  height: 100%;
}

.echartsTooltip {
  color: white;
  padding-bottom: 14px;

  .header {
    padding: 14px 19px 10px;
    font-weight: 600;
    color: #FFFFFF;
  }

  .date {
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, .6);
    font-size: 14px;
    font-weight: 400;
  }

  .items {
    overflow-y: scroll;
    padding: 0 15px;
  }

 .item {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   padding: 6px 4px;
   color: #FFFFFF;

   .name{
     display: inline-flex;
     align-items: center;
     overflow: hidden;
     white-space:nowrap;
     text-overflow: ellipsis;
   }

   .value {
     font-weight: 600;
     padding-left: 32px;
   }

   &.disabled {
     opacity: 0.5;
   }
 }
}

.legend {
  margin: 0 15px 0 19px;
}

.tooltip {
  visibility: visible!important;
  @include blurred-background(60px, transparent, rgba(87, 101, 119, 0.95));
  border-radius: 5px;
  padding: 0!important;
}
