.wrapper {
  display: flex;
  background-color: rgba(30, 47, 67, 0.9);
}

.sidebarMenu {
  width: 56px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  z-index: 10;

  &::before {
    content: '';
    background-color: rgba(30, 47, 67, 0.9);
    backdrop-filter: blur(40px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 56px;
    height: 100vh;
  }
}
