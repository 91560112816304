.container {
  display: flex;
  align-items: center;
  flex-grow: 6;

  .slider {
    display: flex;
    align-items: center;
    flex-grow: 20;

    .value {

      &.left {
        margin-right: 10px;
      }

      &.right {
        margin-left: 10px;
      }
    }

    .icon {
      position: relative;
      top: -22px;

      input {
        display: block;
        margin-top: 13px;
        font-size: 14px;
        position: absolute;
        font-style: normal;
        font-weight: 400;
        -moz-appearance: textfield;
        background: white;
        width: 70px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &.disabled {
          border: none;
          background: transparent;
          color: white;
        }

        &:hover {
          background: white;
          border: 1px solid black;
          color: #1F303D;
        }
      }
    }
  }
}
