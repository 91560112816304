.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  top: 0;
  background: rgba(255, 255, 255, .7);

  .body {
    text-align: center;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #1A1B1E;
      margin-bottom: 0;
    }

    .subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #1A1B1E;
      margin: 0;
    }

    .content {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1A1B1E;
      opacity: 0.5;
    }


  }
}
