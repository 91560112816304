.wrapper {
  position: absolute;
  right: calc(50% - 175px - 28px);
  top: 15px;
  z-index: 999;
  width: 350px;
  transition: .4s;

  &.open {
    right: calc(50% - 175px - 198px);
  }

  .container {
    position: relative;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    transition: .4s;

    .dropdownBlock {
      padding: 4px 4px 4px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .dropdown {
        width: 220px;
      }
      &::before {
        content: '';
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        height: 38px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        box-shadow: 0 0 10px rgba(19, 29, 37, 0.1);
        border-radius: 25px;
        backdrop-filter: blur(20px);
      }
    }

    .catchmentButtons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;
      
      .smallerIcon {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .catchmentDropdowns {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }


}
