.table {
}
.header {
  display: flex;
}

.item {
  width: 140px;
  height: 45px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, .6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    padding: 0 auto;
  }

  &.borderRight {
    border-right: 1px solid rgba(255, 255, 255, .1);
  }

  &.colorLabel {
    cursor: pointer;
    flex-direction: column;

    .colorStripe {
      position: absolute;
      left: 2px;
      height: 100%;
      width: 10px;
      background-color: #F2C94C;
    }

    &.grey {
      font-weight: 600;
      background-color: rgba(255, 255, 255, .1);
    }
  }
}

.data {
  border: 1px solid rgba(255, 255, 255, .1);
}

.row {
  display: flex;
  border-bottom: 1.5px solid rgba(255, 255, 255, .1);
  height: 45px;
}

.leftPanel {
  display: grid;
  margin-top: 39px;
  .item {
    width: 160px;
    justify-content: start;
  }
}

.light {
  color: white;
}

.content {
  display: flex;

  &.noScroll {
    overflow: hidden;
  }

  &.scroll {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Wokrs for Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  }
}
