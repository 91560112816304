.noDataContainer {
  position: relative;
}

.noDataBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataBody h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.noDataBody h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  margin: 0;
}

.noDataBody p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.6;
  width: 30rem;
  text-align: center;
}
