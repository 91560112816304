.textarea {
  background: linear-gradient(90deg, rgba(27, 45, 65, 0.88) 41.01%, rgba(27, 45, 65, 0.15) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  height: 95px;
  padding: 6px 10px;
  color: white;
  overflow-y: scroll;
  width: 250px;

  .polygon {
    .title {
      margin: 0;
      color: rgba(255, 255, 255, 0.4);
      font-weight: 400;
      font-size: 14px;
    }
    .coordinates {
      outline: none;
      font-size: 14px;
    }
  }

}
