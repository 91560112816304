@import "../../../style/variables.scss";

.circleButton {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: $visuals-background-color;
    backdrop-filter: blur($visuals-background-blur);
    padding: 0;

    svg path, svg circle {
        fill: $visuals-color;
    }

    &.active {
        background: $visuals-background-color-active;
        svg path, svg circle {
            fill: $visuals-color-active;
        }
    }

    &.disabled {
        // background: $visuals-background-color-alternative;
        opacity: 0.7;
        svg path, svg circle {
            fill: $font-color-secondary;
        }
    }

    &.shadow {
        box-shadow: 0px 0px 7px 5px rgba(19, 29, 37, .4);
    }
}
