@import "../../../style/mixin";

.wrapper {
  @include blurred-background(15px, rgba(9, 16, 29, 0.5));
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  border-radius: 25px;

  .number {
    background: rgba(9, 16, 29, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2px 9px;
    border-radius: 50%;
    color: white;
  }

  .tools {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      padding: 0 10px;
      cursor: pointer;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }
}
