.container {
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .contentContainer {
    position: relative;

    .contentContainerOverlay {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 2rem;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}
