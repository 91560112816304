.wrapper {
  position: relative;
  .icon {
    padding: 3px 0 0;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;

    &.active {
      background-color: #00b2f1;
      path {
        fill: #1f303d;
      }
    }
  }

  .container {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
    backdrop-filter: blur(80px);
    border-radius: 5px;
    width: 235px;
    position: fixed;
    z-index: 1000;

    .title {
      font-weight: 600;
      font-size: 16px;
      color: white;
      padding: 15px 0 0 15px;
      margin: 0 0 10px 0;
    }

    .list {
      padding: 10px 0;
      .item {
        padding: 7px 12px 7px 15px;
        font-size: 14px;
        cursor: pointer;
        transition: .2s linear;
        display: flex;
        align-items: center;

        &:hover {
          font-weight: 600;
          color: #1F303D;
          background: #00B2F1;
        }
      }
    }
  }
}

