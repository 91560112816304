.information {
  color: white;
  font-size: 14px;

  .row {
    display: flex;
    justify-content: space-between;

    .value {
      font-weight: 600;
    }
  }

  p {
    margin: 0;
  }
}
