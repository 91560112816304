.switcher {
  display: flex;
  justify-content: space-between;
  background: rgba(9, 16, 29, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 25px;
  padding: 3px;
}


.item {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2.5px 16px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  &.active {
    color: #1F303D;
    background: #00B2F1;
    font-weight: 600;
  }
}

.skeleton {
  height: 31px;
  border-radius: 25px!important;
}
