.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.titles {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 15px 15px 20px;
}

.profilePicture {
  display: flex;
  justify-content: center;
}

.userName {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.titleRole {
  display: flex;
  justify-content: center;
  color: #adb3bb;
}

.company {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.companyName {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.error {
  text-align: center;
  color: red;
  font-size: 11px;
}
