.switcher {
  display: flex;
  background: rgba(9, 16, 29, 0.4);
  backdrop-filter: blur(15px);
  border-radius: 25px;

  .button {
    padding: 8px 12px;
    cursor: pointer;
  }

  div {
    display: flex;
    svg {
      cursor: pointer;
    }
  }
}
