.titleContainer {
    display: flex;
    flex-direction: row;
}

.title {
    display: flex;
    margin: 0 0 32px;
    font-size: 32px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.headerNavLink {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/

    justify-content: left;
}

.detailsRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.verticalAlign {
    align-items: center;
}

.detailsProperty {
    display: flex;
    flex-direction: column;
    width: 200px;
    font-weight: bold;
}

.detailsPropertyTooltip {
    flex-direction: row;
    align-items: center;
}

.detailsPropertyTooltip > div :nth-child(1) {
    width: 20px;
    text-align: center;
}

.detailsValue {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

.header *, .row * {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.input {
    border-radius: 4px;
    padding: 4px;
    outline: none;
    border: 1px solid var(--color-separator);
}

.buttonContainer {
    margin-left: 4px;
}

.reportItems {
    margin-top: 16px;
    max-width: 1280px;
}

.reportItemsHeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 16px;
    align-items: center;
}

.reportItemsHeaderRow * {
    display: flex;
    flex: 1;
}

.reportItemRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
}

.reportItemRow * {
    display: flex;
    flex: 1;
}

.optionsField {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-right: 20px;
}

.optionsFieldInnerRow {
    display: flex;
    flex-direction: row;
}

.optionsDeleteFieldRow {
    display: flex;
    flex-direction: row;
}

.duplicateReportIcon {
    fill: #4a9cb3 !important;
    margin-left: 0.25rem;
}

.optionIcon {
    fill: #4a9cb3 !important;
}

.optionDeleteIcon {
    fill: #bb3e3e !important;
    cursor: pointer;
    margin-left: 0.25rem;
}

.optionEditIcon {
    /*fill: #4a9cb3 !important;*/
    cursor: pointer;
    /*margin-left: 0.25rem;*/
}

.optionEditIconWrapper {
    margin-bottom: 0.35rem;
}

.optionSaveReportIcon {
    fill: #43cf21 !important;
    cursor: pointer;
    margin-left: 0.25rem;
}

.optionDiscardReportChangesIcon {
    fill: #bb3e3e !important;
    cursor: pointer;
    margin-left: 0.25rem;
}

.riTooltip {
    display: unset;
    clear: unset;
}

.reportInputField {
    padding: 0.25rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid var(--color-separator);
    width: 100%;
    margin-left: 1.5rem;
}

.reportDescription {
    padding: 0.25rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid var(--color-separator);
    resize: none;
    width: 100%;
    margin-left: 1.5rem;
}

.errorMessage {
    color: var(--color-invalid);
    margin-top: 0;
    margin-bottom: 1rem;
}

