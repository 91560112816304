.information {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-top: 1px solid rgba(255, 255, 255, .1);
  align-items: center;

  .left {

    span {
      font-size: 14px;
    }

    a {
      color: #00B2F1;
      margin-left: 5px;
    }
  }
}
