@import "../../../style/variables.css";

.box {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 32px;
    bottom: 32px;
    border-radius: 8px;
    background-color: #167e20;
    color: var(--color-text-light);
    padding: 16px;
}
