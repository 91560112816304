.information {
  .row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: white;

    .value {
      font-weight: 600;
    }

  }

  p {
    margin: 0 0 10px 0;
  }
}
