.modal {
    /*flex: 1;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    min-width: 60vw;
    min-height: 80vh;
    background-color: var(--color-background);
    display: flex;
    position: absolute;
    top: 5vh;
    left: 20vw;
    outline: none;
    border: 1px solid var(--color-separator);
    border-radius: 4px;
    max-height: 80vh;
    /*overflow: scroll;*/
    padding: 10px;
    border: 2px solid #c6e9ec;
    border-radius: 15px;
}

.modalContent {
    padding: 8px;
    flex: 1;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*overflow-y: auto;*/
}

.optionIcon {
    fill: #4a9cb3 !important;
}

.modalButtons {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.nameBox {
}

.descriptionBox {
    display: flex;
    flex-direction: row;
    width: 30vw;
    height: 12vh;
    /*background-color: #ed1c6f;*/
    /*background-color: lightyellow;*/
}

.input {
    border-radius: 4px;
    padding: 4px;
    outline: none;
    border: 1px solid var(--color-separator);
    width: 30vw;
    /*background-color: lightyellow;*/
}

.editedInput {
    border-radius: 4px;
    padding: 4px;
    outline: none;
    border: 1px solid var(--color-separator);
    width: 30vw;
    background-color: lightyellow;
}

.descriptionButtons {

}

.saveButton {
    display: unset;
    /*align-items: center;*/
    /*align-content: center;*/
    /*vertical-align: center;*/
    /*horiz-align: center;*/
    background-color: #f5f5f5;
}

.resetDescriptionButton {
    display: unset;
    background-color: #f5f5f5;
}

.delimiter {
    height: 2px;
    width: 100%;
}

.resetSettingsButton {
    padding-left: 27px;
}

.bottomContainer {

}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;
    overflow-y: auto;
}

/*.formRow {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/

.formRow {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    align-items: center;
    width: 57vmax;
    margin-bottom: 16px;
}

.fieldName {
    display: flex;
    font-weight: bold;
    text-align: left;
    width: 40%;
    flex-direction: row;
    align-items: center;
}


.valueContainer {
    display: flex;
    flex-direction: column;
    width: var(--value-row-width);
}
