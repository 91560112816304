@import "../../style/variables.scss";

.root {
  position: relative;

  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1F303D;
  font-size: 12px;

  .pin {
    position: absolute;
    top: 0;
    left: 0;  
    display: block;
    width: 20px;
    height: 67px;
    padding-bottom: 2px;
    transform: translate(-50%, -100%);
    cursor: pointer;
    border-bottom: 1px solid $visuals-color;
  }
  .numericValue {
    display: block;
    font-size: 10px;
    margin-top: 3px;
    color: $font-color-primary;
  }
}

.barChartTooltip {
  font-family: 'Open Sans', sans-serif;
  backdrop-filter: blur(40px);
}
