.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;

  p {
    margin: 0;
    font-weight: 700;
  }
}
