.days {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7, 1fr);
  position: absolute;
  color: rgba(255, 255, 255, .6);
  bottom: 33px;
  font-size: 12px;
  left: 0;
  right: 0;
  margin-left: 70px;
  margin-right: 35px;

  span {
    text-align: center;
    padding-left: 12px;
  }
}
