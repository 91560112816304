.navigation.rounded :global(.mapboxgl-ctrl-group) {
  border-radius: 25px;
}

.navigation :global(.mapboxgl-ctrl-group) {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 4px 4px 0 0;
}

.navigation :global(button.mapboxgl-ctrl-icon) {
  width: 42px;
  height: 42px;

  border-color: rgba(0, 0, 0, 0.3);
  filter: invert(1) contrast(1.5);
}
