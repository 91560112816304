@import '../common/style/variables.scss';

.createReportPanel {
    padding-left: 10px;
    .row {
        display: flex;
        flex-direction: row;
        gap: 15px;
    
        .circle {
            display: flex;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            color: $font-color-primary;
            background: $visuals-background-color;
        }
        .title {
            display: flex;
            height: 30px;
            align-items: center;
            color: $font-color-primary;
            font-weight: 600;
        }
        .subtitle {
            color: $font-color-secondary
        }
    
        .placeholder {
            width: 15px;
            height: 100px;
            margin-left: 15px;
    
            &.verticalLine {
                border-left: 1px solid $visuals-line-color;
            }
        }
    
        &.active {
            .circle {
                color: $visuals-color-active;
                background: $visuals-background-color-active;
            }
            .title {
                color: $visuals-background-color-active;
            }
        }
    }
}

