.form {
  display: flex;
  flex-direction: column;
  
}

.formTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.formRow {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.formRowFfi {
  margin-bottom: 1rem;
  width: 30rem;
  display: flex;
  justify-content: space-between;
}

.ffiHeading {
  justify-content: flex-start;
}

.form label {
  font-weight: bold;
}

.form input {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.form input[type='checkbox'] {
  width: 2rem;
}

.form select {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formTop hr {
  border-top: 1px dashed;
}

.inputWithError {
  display: flex;
  flex-direction: row;
}

.formTopWithError {
  display: flex;
}

.emailError {
  position: relative;
  top: 142px;
  left: 15px;
}

.passwordError {
  position: relative;
  top: 240px;
  left: 15px;
}