.dropdown {
  position: relative;
  .active {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    p {
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      cursor: pointer;
      .cross {
        width: 10px;
        height: 10px;
        path {
          fill: #999;
        }
      }
    }
  }

  .list {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .6);
    box-sizing: border-box;
    top: 100%;
    width: 100%;
    z-index: 1000;

    .items {
      margin: 0;
      padding: 0;
      height: 200px;
      overflow-y: scroll;

      .item {
        box-sizing: border-box;
        color: rgba(51, 51, 51, 0.8);
        cursor: pointer;
        display: flex;
        padding: 8px 10px;

        &:hover {
          background-color: #f2f9fc;
          color: #333;
        }

        &.selected {
          background-color: #f2f9fc;
          color: #333;
        }

        span {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    margin: 5px 0;

    button {
      cursor: pointer;
    }
  }
}
