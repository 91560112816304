.manager {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 40px;
  padding-bottom: 30px;

  .borderBottom {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  }

  .middle {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  button {
    padding: 10px 30px;
    width: 300px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  grid-column-gap: 50px;

  .leftPanel {
    width: 100%;

    .button {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .center {
    border-left: 1px solid rgba(255, 255, 255, .3);
  }

  .rightPanel {
    width: 100%;

    &:before {
      content: ' ';
      //display: block;
      //position: absolute;
      left: -6.5px;
      width: 1px;
      background: rgba(255, 255, 255, .3);
    }
  }
}

.list {
  margin-top: 20px;
  height: 315px;
  overflow-y: scroll;

  .noLocations {
    h3 {
      color: white;
      margin: 0;
      font-size: 14px;
      margin-bottom: 20px;
    }

    p {
      color: rgba(255, 255, 255, .6);
      margin: 0;
      font-size: 14px;
    }
  }
}
