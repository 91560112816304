.seasonalityTooltip {
  font-size: 16px;
  color: #1F303D;
  font-family: 'Open Sans' sans-serif;
  backdrop-filter: blur(40px);
  padding: 0!important;

  .header {
    padding: 14px 19px 10px;
    font-weight: 600;
    color: #FFFFFF;

    .date {
      opacity: 0.5;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 6px 4px;
    color: #FFFFFF;

    .value {
      font-weight: 600;
      padding-left: 32px;
    }
  }

  .items {
    overflow-y: scroll;
    padding: 0 15px;
  }
}
