.wrapper {
  position: relative;
  width: 100%;

  .field {
    position: relative;
    color: rgba(255, 255, 255, .6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    span, input {
      display: inline-block;
      margin-right: 5px;
    }
    input {
      display: inline-block;
      margin-right: 5px;
      color: rgba(255, 255, 255, .6);
      width: calc(100% - 30px);
      &::placeholder {
        color: rgba(255, 255, 255, .6);
    }
    }

    .cross {
      svg {
        width: 13px;
        height: 13px;
        path {
          fill: rgba(255, 255, 255, .6);
        }
      }
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      bottom: -6.5px;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, .3);
    }

    &.focus {
      color: #00B2F1;
      font-weight: 600;
    }

  }

    .list {
      position: absolute;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(25px);
      border-radius: 5px;
      width: 100%;
      top: 32px;
      z-index: 10;

      input {
        padding: 10px 15px;
      }

      .items {
        &.scroll {
          overflow-y: scroll;
        }

        .noOptions {
          color: white;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 5px 0;
          font-size: 14px;
        }
      }
    }
}
