.root {
  position: relative;

  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1F303D;
  font-size: 12px;

  .pin {
    position: absolute;
    top: 0;
    left: 0;  
    display: block;
    width: 140px;
    height: 56px;
    transform: translateY(-100%);
    cursor: pointer;

    .primaryIcon {
      position: absolute;
      top: 0;
      left: -42px;
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      display: flex;
      height: 21px;      
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 3px 3px 0px 0px;
      margin-bottom: 1px;
      padding-left: 8px;
      align-items: center;

      &_primary {
        @extend .title;
        background-color: white;
      }
    }

    .value {
      display: flex;
      height: 18px;
      background: var(--pin-color, #EFA18D);
      box-shadow: 0px 0px 10px rgba(19, 29, 37, 0.4);
      border-radius: 0px 0px 3px 0px;
      padding-left: 8px;
      align-items: center;
    }

    .arrow {
      height: 0px;
      width: 0px;
      border-top: 16px solid var(--pin-color, #EFA18D);
      border-right: 8px solid transparent;
    }

  }
}
