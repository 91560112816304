.menuItem {
  margin: 20px 12px 20px;
  .menuItemButton {
    width: 33.5px;
    height: 32px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menuItemButton:hover,
  .menuItemButton.selected {
    background-color: #00b2f1;
    path {
      fill: #1f303d;
    }
  }
}
