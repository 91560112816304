.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 96px;
}

.settingContainer {
    display: flex;
    flex-direction: column;
}

.addEntryButtons {
    display: flex;
    margin-top: 48px;
    flex-direction: row;
}

.actionButton {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    height: 32px;
}

.saveSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
}

.savedMessage {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    color: var(--color-primary);
}
