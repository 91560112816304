.information {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 14px;

  .value {
    font-weight: 600;
  }


  p {
    margin: 0;
  }
}
