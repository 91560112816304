.wrapper {
  .background {
    position: fixed;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(40px);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: .5s linear;
    opacity: 0;
    visibility: hidden;
  }

  .content {
    width: 100%;
    height: calc(100%);
    display: block;
    overflow-y: scroll;
    opacity: 0;
    transition: .3s linear;
  }

  .position {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: #364658;
    padding: 0;
    box-shadow: 0px 0px 15px rgba(19, 29, 37, 0.3);
    height: 0;
    width: 0;
    transition: .8s linear;
    border-radius: 50%;
  }

  &.open {
    .background {
      opacity: 1;
      transition-delay: .7s;
      z-index: 1000;
      visibility: visible;
    }

    .position {
      z-index: 1000;
      width: 550px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 15px 20px;
      border-radius: 6px;
    }

    .content {
      transition-delay: 1s;
      opacity: 1;
    }
  }
}
