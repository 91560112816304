.table {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

.header *, .row * {
    display: flex;
    flex-direction: column;
    flex: 1;
}
