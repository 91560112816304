.circle {
  position: relative;
  width: 435px;
  height: 429px;

  .controllers {
    position: absolute;
    left: 49.99%;
    transform: translateX(-50%);
    grid-column-gap: 7px;
    display: flex;

    &.top {
      top: 3px;
    }

    &.bottom {
      bottom: 2px;
    }
    span {
      display: block;
      height: 15px;
      width: 2px;
      background: red;
    }
  }

  .wrapper {
    width: 435px;
    height: 429px;
    overflow: hidden;
    position: relative;

    svg {
      position: absolute;
      top: -16%;
      left: -16%;
    }
  }

}
