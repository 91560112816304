.chart {
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 435px;
  width: 1006px;
  margin: 0 auto;

  .center {
    position: absolute;
    left: 43.5%;
    top: 68%;
    transform: translateY(-50%);
    z-index: 10;
  }

  .background {
    position: absolute;
    left: 50%;
    bottom: 7%;
    transform: translateX(-29%);
    z-index: 1;
  }
}

.singleColumnchart {
  position: relative;
}

.tripleColumnChart {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
}

.column1 {
  //justify-content: space-between;
  margin-left: 10px;
}

.column2 {
  //justify-content: space-between;
  margin-left: 30px;
}
