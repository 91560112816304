@import '../../../common/style/mixin.scss';

.chart {
    position: relative;
}

.seasonalityTooltip {
  @include blurred-background(60px, transparent, rgba(87, 101, 119, 0.95));
  font-size: 16px;
  color: #1F303D;
  font-family: 'Open Sans', sans-serif;
  padding: 0!important;

  .header {
    padding: 14px 19px 10px;
    font-weight: 600;
    color: #FFFFFF;

    .date {
      opacity: 0.5;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    color: #FFFFFF;
    padding: 6px 4px;

    .value {
      font-weight: 600;
      padding-left: 32px;
    }
  }

  .items {
    overflow-y: scroll;
    padding: 0 15px;
  }
}
