.header {
 display: flex;
 margin-bottom: 15px;
}

.accountsTable {
 width: 100%;
 border-collapse:collapse;
}

.accountsTable td, .accountsTable th {
 text-align: left;
 padding: 0.30rem 0.25rem;
}

.accountsTable tr:nth-child(even) {
 background-color: #eaeaea;
}
