.small {
  position: relative;
  display: flex;
  width: 22px;
  height: 24px;
  background: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #1f303d;
  clip-path: path("M4.31765 17.4044L10.9973 24L17.6823 17.4044C19.2138 16.0654 20.2966 14.2979 20.7865 12.3367C21.2765 10.3755 21.1506 8.31356 20.4253 6.42477C19.7002 4.53598 18.4101 2.90978 16.7266 1.7622C15.0429 0.61462 13.0457 0 11 0C8.95426 0 6.95683 0.61462 5.27334 1.7622C3.58967 2.90978 2.29973 4.53598 1.5745 6.42477C0.849314 8.31356 0.723335 10.3755 1.21329 12.3367C1.70321 14.2979 2.78595 16.0654 4.31765 17.4044Z");
}

.profile span {
  font-size: 12px;
  font-weight: 600;
}


button:hover .profile {
  background-color: #1f303d;
  color: white;
}

.big {
  display: flex;
  width: 102px;
  height: 120px;
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  align-items: center;
  position: relative;
  color: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(19, 29, 37, 0.3);
  clip-path: path("M17.5888 87.0222L50.9868 120L84.412 87.0222C92.0696 80.3271 97.4833 71.4893 99.9331 61.6834C102.383 51.8776 101.753 41.5678 98.1271 32.1238C94.5016 22.6799 88.051 14.5489 79.6336 8.81101C71.2152 3.0731 61.2289 0 51.0004 0C40.7718 0 30.7847 3.0731 22.3672 8.81101C13.9488 14.5489 7.49913 22.6799 3.87301 32.1238C0.247058 41.5678 -0.382837 51.8776 2.06693 61.6834C4.51654 71.4893 9.93026 80.3271 17.5888 87.0222Z");
}

.wrapper {
  position: relative;

  &.space {
    margin-bottom: 20px;
  }
}

.biggerProfile span {
  font-size: 50px;
}

.image {
  object-fit: cover;
  width: 102px;
  height: 120px;
}

.initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.big {
    font-size: 30px;
  }
}
