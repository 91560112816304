.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .icon {
    display: block;
    margin: 3px 5px 0 7px;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  .skeletonButton {
    height: 30px;
    width: 135px;
    border-radius: 25px;
  }

  .skeletonIcon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .dotsSkeleton {
    width: 10px;
  }

  .options {
    display: flex;
  }

}

.tooltipIcon, .settings {
  position: relative;
  z-index: 2;
}

.actions {
  display: flex;
}

.title {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 25px;
  height: 34px;
  padding: 5px 15px 5px 5px;
  text-decoration: none;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  .iconContainer {
      background: rgba(255, 255, 255, 0.1);
  };

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0 0 0 7px;
  }
}

.menuContainer {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.menuActive {
    background-color: #00B2F1;
    path {
      fill: #323232;
    }
  }
}
