.form {
  display: flex;
  flex-direction: column;
  width: 26rem;
}

.formRow {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form label {
  font-weight: bold;
  padding-top: 5px;
}

.form input, .form textarea {
  padding: 0.25rem;
  outline: none;
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-separator);
  width: 18rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
