@import '../../style/mixin.scss';

.modal {
  @include blurred-background(20px, rgba(255, 255, 255, 0.15), rgba(74, 92, 114, 0.95));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 30px rgba(19, 29, 37, 0.6);
  border-radius: 5px;
  color: white;
  width: 375px;
  padding: 30px;

  .progressBar {
    position: relative;
    height: 10px;
    margin: 40px 0 10px;
    .progress {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: inherit;
      background: #00B2F1;
      border-radius: 5px;
    }

    .background {
      display: block;
      width: 100%;
      height: inherit;
      background: rgba(255, 255, 255, 0.25);
      border-radius: 5px;
    }
  }

  .status {
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
  }

  .information {
    display: flex;
    justify-content: space-between;
  }
}
