.particle {
  position: relative;

  .popup {
    position: absolute;
    z-index: 100;
    top: -100%;
  }

  .information {
    position: absolute;
    display: flex;
    grid-column-gap: 15px;
    grid-row-gap: 8px;
    left: 82px;
    top: 70px;
    transform: translateY(-50%);

    &.single {
      &.left {
        left: -60px;
      }
    }

    &.multiple {
      &.left {
        left: -130px;
      }

      .information {
        width: max-content;
      }
    }

    &.triple {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &.right {
      left: 82px;
    }

    &.left {
      left: -200px;
    }
  }
}
