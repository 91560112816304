.wrapper {
  position: relative;
  width: 352px;

  &:after, &:before {
    position: absolute;
    content: '';
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.1);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &.after {
    &:after {
      right: -6px;
    }
  }

  &.before {
    &:before {
      left: -6px;
    }
  }

  &.during {
    width: 370px;
  }

  .information {
    display: grid;
    grid-template-columns: 12px 1fr max-content;
    grid-column-gap: 7px;
    color: white;
    padding: 5px 10px 5px 15px;
    align-items: center;
    width: 352px;
    border-radius: 3px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: rgb(51,66,85);
      box-shadow: 0px 4px 15px rgba(19, 29, 37, 0.2);
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      z-index: 10;
      border-radius: 3px;
    }

     svg {
       position: relative;
       z-index: 11;
     }

    .title {
      position: relative;
      margin: 0;
      width: 261px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 11;
      font-size: 14px;
    }

    .value {
      position: relative;
      font-weight: 600;
      z-index: 11;
      font-size: 14px;
    }
  }
}

