:root {
    --cp-page-background-color: #F5F5F5;
    --cp-main-bg-color: #1f303d;
    --cp-color-primary: #1f303d;
    --cp-color-secondary: #b8c6cc;

    --cp-default-icon-color: #1F303D;
    --cp-active-fill-color: #00b2f1;
    --cp-hover-color: #2f3f4a;

    --cp-table-striped-row: white;
    --cp-color-black: rgba(19, 29, 37, 1);
    --cp-color-black-20: rgba(19, 29, 37, 0.2);
    --cp-color-black-05: rgba(19, 29, 37, 0.05);

    --cp-white: #fff;
    --cp-black: #000;
    --cp-black-50: rgba(0, 0, 0, 0.5);
}
