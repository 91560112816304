@import "variables.css";

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('./fonts/open-sans-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/open-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-600 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('./fonts/open-sans-v20-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/open-sans-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
}

input, button, select, textarea {
    font-family: 'Open Sans';
    font-size: 16px;
    color: #1F303D;
    border: 0;
    background: none;
    outline: none;
}

/* button {
    padding: 0;
} */

html, body {
    height: 100%;
}

head {
    display: none !important;
}


body * {
    box-sizing: border-box;
}

script {
    display: none !important;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* react-multiselect */
.dropdown-container, .dropdown-content, .panel-content, .select-panel, .select-panel li div, .select-panel ul {
    display: block;
}

.select-panel div input {
    display: inline-block;
}

.select-panel li {
    display: list-item;
}

.dropdown-heading, .select-item, .item-renderer {
    flex-direction: row;
}

/* react-datepicker*/
.react-datepicker__input-container input {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--color-separator);
}

/* scrollbar */
::-webkit-scrollbar {
    width: 8px;
}
  
::-webkit-scrollbar-thumb {
    background: #D4D4D4;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #848484;
}
