.tableu {
  position: absolute;
  right: 0;
  transition: .4s;
  &.full {
    width: calc(100% - 57px);
  }

  &.part {
    width: calc(100% - 396px)
  }
}

.wrapper {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.fullscreen {
  height: 120vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

:global(.fullscreen-enabled) .content {
  overflow: hidden;
  padding: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

:global(.fullscreen-enabled) {
  background-color: white;
  height: 100%;
}

:global(.fullscreen-enabled) body {
  background-color: white;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}
.openButton {
  display: inline-block;
  margin: 10px 30px 10px 0;
  cursor: pointer;
  align-self: flex-end;
}

.exitButton {
  cursor: pointer;
  visibility: hidden;
  display: none;
}

:global(.fullscreen-enabled) .exitButton {
  visibility: visible;
  display: flex;
  margin: 25px 30px 50px 0;
  align-self: flex-end;
}

.iframe {
  height: 100%;
  color: white;
}
