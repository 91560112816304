@import '../../../common/style/mixin.scss';

.wrapper {
  height: 100%;
  position:relative;
  z-index: 8;
  transition: .4s;
  color: white;
  overflow: hidden;
  
  &:before {
    content:"";
    position:absolute;
    z-index:-1;
    @include blurred-background(40px);
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  
  &.expanded {
    width: 340px;
  }

  &.closed {
    width: 0px;
  }

  .panel {
    width: 340px;
    height: 100vh;
  }
}


