@import '../../common/style/variables.scss';

.popupContent {
    color: $font-color-primary;
    text-align: center;

    .title {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 600;
    }
    .subtitle {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 600;
    }
    .logo {
        margin: 20px 0 35px;
        font-size: 14px;
    }
    .paragraph {
        margin: 25px;
    }
    .infoBlock {
        background: $visuals-background-color;
        display: flex;
        width: 100%;
        height: 45px;
        border-radius: 22px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin: 25px 0 40px;
        svg {
            width: 24px;
            padding-right: 10px;
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
    }
}
