.titleContainer {
  display: flex;
  flex-direction: row;
  margin: 15px 15px 30px;

  .backButton {
    background-color: transparent;
    border: none;
    font-size: 14px;
    padding: 0;
    margin-right: 15px;
    cursor: pointer;
  }
  .title {
    font-size: 21px;
    font-weight: bold;
  }
  .content {

  }
}
