.wrapper {
  position: relative;

  .icon {
    position: absolute;
    bottom: 2px;
    cursor: pointer;
    z-index: 10;
  }

  &.left {
    .icon {
      left: 8px;
    }

    input {
      padding-left: 32px;
    }
  }

  &.right {
    .icon {
      right: 8px;
    }
  }
}
