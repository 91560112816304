.wrapper {
  width: 300px;
  font-size: 14px;

  p {
    margin: 0;
  }

  button {
    margin: 40px auto 0 auto;
  }
}
