.reportTable {
  text-align: left;
  width: 100%;
  th {
    font-weight: normal;
    width: 35%;
  }
  td {
    padding-left: 1rem;
    width: 65%;
  }
}

.parametersBlock {
  h4 {
    margin-bottom: 5px;
  }
  > ul {
    margin-top: 5px;
    list-style-type: none;
    padding-left: 5px;

    li {
      text-indent: 0;
      white-space: pre-line;
      &:before {
        content: "-";
        text-indent:  -5px;
        padding-right: 5px;
      }
    }
  }

  table {
    text-align: left;
    width: 100%;
    th {
      font-weight: normal;
      width: 35%
    }
    td {
      padding-left: 1rem;
      width: 65%;
      ul {
        list-style-type: none;
        padding-left: 0;
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}