.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }

      img {
        &:nth-child(1) {
          animation: move 15s -2s linear infinite;
        }

        &:nth-child(2) {
          animation: move 13s 1s linear infinite;
        }

        &:nth-child(3) {
          animation: move 13s 2s linear infinite;
        }

        &:nth-child(4) {
          animation: move 15s 4s linear infinite;
        }

        &:nth-child(5) {
          animation: move 13s 6s linear infinite;
        }

        &:nth-child(6) {
          animation: move 14s 7s linear infinite;
        }

        &:nth-child(7) {
          animation: move 13s 9s linear infinite;
        }

        &:nth-child(8) {
          animation: move 14s 11s linear infinite;
        }

        &:nth-child(9) {
          animation: move 13s 13s ease-in-out infinite;
        }
      }
}

@keyframes move {
  0% {
    transform: translateY(1700px);
    opacity: 1;
  }

  100% {
    transform: translateY(-1600px);
    opacity: 1;
  }
}
